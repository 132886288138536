import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_MESSAGES_BY_QUOTE_REQUEST, GET_RECEIVER_ID } from "./queries";
import {
  configQuoteRequest,
  configSupplierChat
} from "../../../../../store-config";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core/styles";
import MessageForm from "./MessageForm";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";

let countdownTimeout;
const pathname = typeof window !== "undefined" && window.location.pathname;
const useStyles = makeStyles(theme => ({
  chatWrapperMessageDetail: {
    maxHeight: "75vh",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  messageContainer: {
    height: "500px",
    minHeight: "calc(100% - 93px)",
    maxHeight: "calc(100% - 93px)",
    // overflowY: "scroll",
    overflowX: "hidden",
    width: "100%",

    "& > div": {
      overflowY: "visible"
    }
  },
  chatItemMessageDetail: {},
  chatContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.papsendMessageLoadinger,
    overflow: "scroll"
  },
  chatContainerLeft: {
    height: "100%",
    overflow: "scroll",
    padding: 0,
    borderRight: "1px solid #f1f1f1",
    "-ms-overflow-style": "none" /* IE and Edge */,
    scrollbarWidth: "none",

    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  chatInput: {
    background: "#F7F9FA",
    border: "solid 1px #707070",
    borderRadius: 40,
    marginRight: 15,
    padding: "15px 30px"
  },
  chatOwnerContainer: {
    display: "flex",
    flexDirection: "row-reverse"
  },
  chatOtherContainer: {
    display: "flex",
    flexDirection: "row"
  },

  chatOwnerText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  chatOwnerAvatar: {
    minWidth: "max-content",
    marginLeft: 15
  },
  textChatContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  chatPopupContainer: {
    flex: 1
  },
  chatPopupWrapper: {
    position: "absolute",
    width: "70vw",
    height: "max-content",
    minHeight: "80vh",
    background: "rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;",
    border: "2px solid #fff",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column"
  },
  listItemAvatar: {
    minWidth: "max-content",
    marginRight: 15
  },
  listItemAvatarChild: {
    // width: 30,
    // height: 30,
    marginRight: 10
  },
  chatWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  chatHeader: {
    position: "absolute",
    right: 0,
    display: "flex",
    justifyContent: "flex-end",
    margin: "10px"
  },
  chatHeaderMobile: {
    display: "flex",
    justifyContent: "space-between"
  },
  buttonViewMessageLabel: {
    fontSize: "0.6rem"
  },
  listSubHeader: {
    fontSize: "16px",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "bold",
    color: "#3F57C6"
  },
  listItem: {
    padding: 0
  },
  divider: {
    background: "rgba(112, 112, 112, 1)",
    marginBottom: 15,
    marginTop: 15
  },
  avatar: {
    border: "3px solid #3F57C6",
    background: "#F6F6F6"
  },
  listItemText: {
    "& > .MuiListItemText-primary": {
      color: "#3F57C6"
    }
  },
  avatarDetail: {
    width: 40,
    height: 40,
    background: "#F6F6F6",
    border: "2px solid",

    "& > .MuiAvatar-fallback": {
      color: "#4A4A4A"
    }
  },
  listItemTextSupplier: {
    "& > .MuiListItemText-primary": {
      fontSize: 18,
      fontFamily: "'Poppins', sans-serif",
      color: "#4A4A4A"
    },

    "& > .MuiListItemText-secondary": {
      fontSize: 12,
      color: "#3F57C6",
      fontWeight: "normal"
    }
  },

  listItemTextOwner: {
    "& > .MuiListItemText-primary": {
      fontSize: 18,
      fontFamily: "'Poppins', sans-serif",
      color: "#3F57C6",
      textAlign: "right"
    },

    "& > .MuiListItemText-secondary": {
      fontSize: 12,
      color: "#4A4A4A",
      textAlign: "right",
      fontWeight: "normal"
    }
  },
  messageContentSupplier: {
    width: "max-content",
    maxWidth: "60%",
    alignSelf: "flex-start",
    background: "#F6F6F6",
    padding: 12,
    borderRadius: 12,
    marginTop: 5,
    color: "#4A4A4A",
    lineHeight: "18px",
    whiteSpace: "pre-line"
  },
  messageContentOwner: {
    width: "max-content",
    maxWidth: "60%",
    alignSelf: "flex-end",
    background: "rgb(217 224 252)",
    padding: 12,
    borderRadius: 12,
    marginTop: 5,
    color: "#4A4A4A",
    lineHeight: "18px",
    whiteSpace: "pre-line"
    // overflow: "hidden",
    // textOverflow: "ellipsis",
  }
}));

const MessageDetail = ({
  currentChat,
  currentConversation,
  handleCloseModal,
  sendMessage,
  mobileView,
  setCurrentConversation,
  inputFIeldRef,
  supplierInfo,
  count,
  setCount,
  chatSubscription,
  statusMarketplaceSupport,
  messageBoxMutation,
  currentQuoteBasket,
  usingMarketplaceSupport,
  usingScrollToBottom
}) => {
  const classes = useStyles();
  const messageDetailEndRef = useRef();
  const messageDetailRef = useRef();

  const { activeDist, chatWithVendor, quoteRequestId } = useSelector(
    state => state.chatReducer,
    shallowEqual
  );

  const [getMessagesByQuoteRequest] = useLazyQuery(
    GET_MESSAGES_BY_QUOTE_REQUEST
  );
  const { loading } = useQuery(GET_RECEIVER_ID, {
    variables: {
      vendorId: activeDist.vendorId
    },
    skip: typeof activeDist.vendorId === "undefined"
  });

  const [countShowConfirmForQuoteBasket, setCountShowConfirmForQuoteBasket] =
    useState(1);
  const [showConfirmForQuoteBasket, setShowConfirmForQuoteBasket] =
    useState(false);
  const [startCountdown, setStartCountdown] = useState(false);
  const [displayAllMessages, setDisplayAllMessages] = useState(false);
  const [
    currentConversationWithMessagesByQuoteRequestId,
    setCurrentConversationWithMessagesByQuoteRequestId
  ] = useState({});

  const showChatQuoteBasket = useSelector(
    state => state.chatReducer.showChatQuoteBasket,
    shallowEqual
  );

  const login = useSelector(state => state.loginReducer, shallowEqual);

  const scrollToBottom = () =>
    messageDetailEndRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest"
    });

  const handleViewLastMessage = () =>
    messageDetailRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });

  useEffect(() => {
    if (usingScrollToBottom) {
      scrollToBottom();
    }
  }, [currentConversation]);

  useEffect(() => {
    if (startCountdown && countShowConfirmForQuoteBasket === 1) {
      messageBoxMutation({
        variables: {
          chatId: currentChat?.id,
          senderId: supplierInfo?.id,
          content: "Please wait while we connect to the supplier....",
          quote_request_id: currentQuoteBasket?.id + ""
        }
      }).then(() => {
        setCountShowConfirmForQuoteBasket(0);
      });
    }

    if (startCountdown && countShowConfirmForQuoteBasket === 0) {
      countdownTimeout = setTimeout(() => {
        const messageListSupplier = currentConversation.messages.filter(
          message => message.user.email !== login.loginName
        );
        const supplierInfo = currentChat?.chat_users?.find(
          item => item.user.email !== login.loginName
        ).user;

        const differentSeconds = moment(new Date()).diff(
          moment(
            messageListSupplier[messageListSupplier.length - 1].created_at
          ),
          "seconds"
        );

        if (differentSeconds > 10) {
          messageBoxMutation({
            variables: {
              chatId: currentChat?.id,
              senderId: supplierInfo?.id,
              content:
                "Unfortunately the supplier is unable to respond at this moment and you will be notified as soon as possible.",
              quote_request_id: currentQuoteBasket?.id + ""
            }
          }).then(() => {
            setCurrentConversation(currentChat);
          });
        }

        setStartCountdown(false);
        clearTimeout(countdownTimeout);
      }, 10000);
    }

    return () => {
      if (countdownTimeout) {
        clearTimeout(countdownTimeout);
      }
    };
  }, [currentConversation, startCountdown]);

  const chatWindowTitle = brandName => {
    return (
      <Typography variant="h6" style={{ fontWeight: 600, marginBottom: 10 }}>
        Connecting with {brandName}
      </Typography>
    );
  };

  const isTheSameUser = (userEmailFromChat, currentUserEmail) => {
    return userEmailFromChat === currentUserEmail;
  };

  const handleSwitchChange = async e => {
    setDisplayAllMessages(e.target.checked);
    if (e.target.checked) {
      const { data } = await getMessagesByQuoteRequest({
        variables: {
          quoteRequestId: quoteRequestId
        }
      });
      // console.log(data.message);
      setCurrentConversationWithMessagesByQuoteRequestId({
        ...currentConversation,
        messages: [...data.message]
      });
    } else {
      setCurrentConversation(currentConversation);
    }
  };
  const selectMessages = (currentConversationLocal, currentVendorLocal) => {
    const supplierVid = currentConversationLocal?.chat_users.find(
      us => us.user?.vendor?.supplier_vendorId
    ).user.vendor.supplier_vendorId;
    if (currentVendorLocal === supplierVid) {
      return (
        <Grid item>
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="primary"
                    checked={displayAllMessages}
                    onChange={handleSwitchChange}
                  />
                }
                label={`Show messages for ${quoteRequestId}`}
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>
        </Grid>
      );
    }
  };

  const selectTheSourceOfMessages = (
    currentConversationLocal,
    quoteRequestId,
    currentVendorLocal
  ) => {
    // console.log("kril", currentVendorLocal, chatWithVendor);
    const supplierVid = currentConversationLocal?.chat_users.find(
      us => us.user?.vendor?.supplier_vendorId
    ).user.vendor.supplier_vendorId;
    if (
      chatWithVendor === supplierVid &&
      quoteRequestId &&
      displayAllMessages
    ) {
      console.log("ajidfs - 1");
      return currentConversationWithMessagesByQuoteRequestId;
    } else {
      console.log(
        "ajidfs - 2",
        currentVendorLocal,
        supplierVid,
        quoteRequestId
      );
      return currentConversationLocal;
    }
  };
  return (
    <>
      {mobileView ? (
        <Box className={classes.chatHeaderMobile}>
          {showChatQuoteBasket && count !== 3
            ? loading && (
                <Typography
                  variant="h6"
                  style={{ fontWeight: 600, marginBottom: 10 }}
                >
                  Connecting with {supplierInfo.vendor.brand}
                </Typography>
              )
            : ""}

          <IconButton
            style={{ padding: 0, paddingBottom: 12 }}
            onClick={handleCloseModal}
          >
            <CancelIcon />
          </IconButton>
        </Box>
      ) : !activeDist.vendorId && statusMarketplaceSupport ? (
        <Typography variant="h6" style={{ fontWeight: 600, marginBottom: 5 }}>
          Please type any message below if you need from help Marketplace
          Support
        </Typography>
      ) : (
          showConfirmForQuoteBasket && configQuoteRequest === "CHAT"
            ? null
            : showChatQuoteBasket && configQuoteRequest === "CHAT"
        ) ? (
        <>{chatWindowTitle(supplierInfo?.vendor?.brand)}</>
      ) : configQuoteRequest === "MESSAGE" && usingMarketplaceSupport ? (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            {chatWindowTitle(
              currentConversation?.chat_users?.find(
                us => us.user.email !== login.loginName
              )?.user?.vendor?.brand ||
                currentConversation?.chat_users?.find(
                  us => us.user.email !== login.loginName
                )?.user?.firstName
            )}
          </Grid>
          {selectMessages(currentConversation, chatWithVendor)}
        </Grid>
      ) : configSupplierChat === "ON" &&
        chatSubscription?.chat?.length > 0 &&
        Object.keys(currentConversation || {}).length > 0 &&
        pathname !== "/" &&
        usingMarketplaceSupport ? (
        <Typography variant="h6" style={{ fontWeight: 600, marginBottom: 5 }}>
          Please type any message below to
          {" " +
            currentConversation?.chat_users?.find(
              us => us.user.email !== login.loginName
            )?.user?.vendor?.brand}
        </Typography>
      ) : (
        Object.keys(currentConversation || {}).length > 0 && (
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: 10
            }}
          >
            <Button
              variant="outlined"
              size="small"
              style={{
                borderRadius: 18
              }}
              classes={{
                label: classes.buttonViewMessageLabel
              }}
              onClick={handleViewLastMessage}
            >
              View past messages
            </Button>
          </Box>
        )
      )}

      <Box className={classes.chatWrapperMessageDetail}>
        <div className={classes.messageContainer}>
          <div ref={messageDetailRef} />
          {selectTheSourceOfMessages(
            currentConversation,
            chatWithVendor
          )?.messages?.map(message => {
            const cssClassForBox = () => {
              if (!message?.user?.email) {
                return null;
              } else if (isTheSameUser(message?.user.email, login.loginName)) {
                return classes.chatOwnerContainer;
              } else {
                return classes.chatOtherContainer;
              }
            };

            return (
              <Box
                key={message.id}
                style={{ marginBottom: 10 }}
                className={cssClassForBox()}
              >
                <ListItemAvatar
                  key={message.id}
                  className={
                    isTheSameUser(message?.user.email, login.loginName)
                      ? classes.chatOwnerAvatar
                      : classes.listItemAvatar
                  }
                >
                  <Avatar
                    className={classes.avatarDetail}
                    style={{
                      borderColor: isTheSameUser(
                        message?.user.email,
                        login.loginName
                      )
                        ? "#3F57C6"
                        : "#4A4A4A"
                    }}
                  />
                </ListItemAvatar>
                <div className={classes.textChatContainer}>
                  <ListItemText
                    key={message.id}
                    primary={
                      isTheSameUser(message?.user.email, login.loginName)
                        ? login.firstName
                        : message?.user?.vendor?.brand
                    }
                    secondary={`${dayjs(message.created_at).format(
                      "HH:mm"
                    )} | ${dayjs(message.created_at).format("dddd MM.DD.YY")}`}
                    className={
                      isTheSameUser(message?.user.email, login.loginName)
                        ? classes.listItemTextOwner
                        : classes.listItemTextSupplier
                    }
                    style={{ margin: 0, flex: 0 }}
                  />
                  <Box
                    className={
                      isTheSameUser(message?.user.email, login.loginName)
                        ? classes.messageContentOwner
                        : classes.messageContentSupplier
                    }
                    title={message.content}
                  >
                    {message.content}
                  </Box>
                </div>
              </Box>
            );
          })}
          <div ref={messageDetailEndRef} />
        </div>

        {Object.keys(currentConversation || {}).length > 0 && (
          <MessageForm
            showChatQuoteBasket={showChatQuoteBasket}
            sendMessage={sendMessage}
            currentConversation={currentConversation}
            setCount={setCount}
            scrollToBottom={scrollToBottom}
            usingScrollToBottom={usingScrollToBottom}
            countShowConfirmForQuoteBasket={countShowConfirmForQuoteBasket}
            setStartCountdown={setStartCountdown}
            setShowConfirmForQuoteBasket={setShowConfirmForQuoteBasket}
            login={login}
            inputFIeldRef={inputFIeldRef}
            setCurrentConversation={setCurrentConversation}
            currentChat={currentChat}
            classes={classes}
          />
        )}
      </Box>
    </>
  );
};

export default MessageDetail;
