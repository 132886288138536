import { PREVIEW, PROJECT_LINK } from "../../project-config";

export default function footerContent(langState, isLoggedIn) {
  let langCode = langState == "en" || langState == "tr" ? "" : `/${langState}`;

  const hideIfLoggedIn = () => {
    if (isLoggedIn) return { style: { display: isLoggedIn ? "none" : "" } };
    return {};
  };

  return {
    about: [
      {
        parent: { attributes: {} },
        children: [
          {
            attributes: {
              to: `https://dibizglobal.com/about/`,
              target: "_blank"
            },
            text: "About Us"
          },
          {
            attributes: {
              href: `https://dibizglobal.com/cookie-policy/`,
              target: "_blank"
            },
            text: "Cookie Policy"
          },
          
        ]
      }
    ],

    aboutMobile: [
      {
        parent: { attributes: {} },
        children: [
          {
            attributes: {
              to: `https://dibizglobal.com/about/`,
              target: "_blank"
            },
            text: "About Us"
          },
          {
            attributes: {
              href: `https://dibizglobal.com/cookie-policy/`,
              target: "_blank"
            },
            text: "Cookie Policy"
          },
          {
            attributes: {
              href: `https://dibizglobal.com/terms-condition/`,
              targer: "_blank"
            },
            text: "Terms Of Use"
          },
          {
            attributes: {
              to: `https://dibizglobal.com/privacy-policy/`,
              target: "_blank"
            },
            text: "Privacy Policy"
          }
        ]
      }
    ],

    quickLinks: [
      {
        parent: { attributes: {} },
        children: [
          {
            attributes: {
              to: `/palm-oil/palm-oil-products/`
            },
            text: "Palm Oil Products"
          },
          {
            attributes: {
              to: `/palm-oil/oleo-and-derivatives/`
            },
            text: "Oleo And Derivatives"
          },
          {
            attributes: {
              to: `/palm-oil/biodiesel-products/`
            },
            text: "Biodiesel Products"
          }
        ]
      }
    ],

    shop: [
      {
        parent: { attributes: {} },
        children: [
          {
            attributes: {
              href: `mailto:info@dibizglobal.com`
            },
            text: "E-mail : info@dibizglobal.com"
          },
          {
            attributes: {
              href: `https://dibizglobal.com/contact/`,
              target: "_blank"
            },
            text: "Contact Us"
          }
        ]
      }
    ],
    socialMedia: [
      {
        parent: { attributes: { className: "socialMedia" } },
        children: [
          {
            attributes: {
              target: "_blank",
              href: "https://www.facebook.com/avetticommerce",
              rel : "noopener noreferrer"
            },
            image: `Facebook`
          },
          {
            attributes: {
              target: "_blank",
              href: "https://www.instagram.com/",
              rel : "noopener noreferrer"
            },
            image: `Instagram`
          },

          {
            attributes: {
              target: "_blank",
              href: "javascript:void(0)",
              rel : "noopener noreferrer"
            },
            image: `Mail`
          }
        ]
      }
    ]
  };
}
