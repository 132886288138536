import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { PROJECT_LINK, PREVIEW } from "../../../project-config";
import * as classes from "../Styles/WishListMobile.module.css";
import { Link, navigate } from "gatsby";
import handleDeleteProductFromWishList from "./handleDeleteProductFromWishList";
import { FormattedNumber } from "react-intl";
import { I18nContext } from "../../../i18n/index";
import PriceTag from "../../../shared/components/PriceTag/PriceTag";
import { handleReplaceImagesWithLargeImagesOnError } from "../../../functions/Utilities";
import { fetchWishList } from "../../../redux/actions/wishListActions";
import useWindowDimensions from "../../../functions/useWindowDimensions";



export default function WishListMobile({ show, close, toggleWish }) {
  const wishListState = useSelector(
    state => state.wishListReducer.wishlist,
    shallowEqual
  );

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();
  const { langCode, currency, translate, priceConvert } =
    React.useContext(I18nContext);

  const renderWishList = () => {
    if (wishListState && wishListState.length > 0) {
      return wishListState.map(wishItem => renderWishListItem(wishItem));
    } else {
      return (
        <p className={classes.noItemsFound}>
          {translate("js.header.wishlist.noitems")}
        </p>
      );
    }
  };

  

  const handleWishItemClicked = seoUrl => {
    close();
    navigate(seoUrl);
  };

  const handleDeleteItemFromWishList = (event, id, dispatch) => {
    console.log("wishlist001", handleDeleteProductFromWishList);

    event.stopPropagation();
    handleDeleteProductFromWishList(id, dispatch);
  };

  const renderWishListItem = wishItem => {
    console.log('wishItemwishItem', wishItem);
    //let { itemid, title, code, prices, image, seoUrl } = wishItem.item;
    const itemid = wishItem?.item?.itemid;
    const title = wishItem?.item?.title;
    const code = wishItem?.item?.code;
    const seoUrl = wishItem?.item?.seoUrl;
    const image = wishItem?.item?.image;

    let url = `/${seoUrl?.replace('product/', '')}`;
    let imageKitImage = `https://ik.imagekit.io/ofb/dev/store/20180522154/assets/items/largeimages/${code}.jpg?tr=ar-1-1,dpr-2,pr-true,f-auto,h-150`;

    //url = `/${url}`;
    //let description =
    // desc && typeof desc === "string" && desc.replace('\\"', '"');
    return (
      <div
        className={classes.wishItemWrapper}
        //onClick={() => handleWishItemClicked(seoUrl)}
        key={itemid}
      >
        {/* <div className={classes.wishItemImage}>
          <img
            src={imageKitImage}
            alt={`${title}`}
          onError={handleReplaceImagesWithLargeImagesOnError}
          />
        </div> */}
        <div className={classes.wishItemDetails} style={{ overflow: "hidden" }}>
          <div className={classes.wishItemDesc}>
            <span>{code}</span>
            <Link to={url}><b>{title}</b></Link>
          </div>
          {/* <div className={classes.wishItemCode}>
            <label>Code:</label>
            <span>{code}</span>
          </div> */}
          {/* <div className={classes.wishItemPrice}>
            $ {prices[0].listprice}
            {price.value && (
              <PriceTag
                value={{
                  integer:
                    price.type !== "empty"
                      ? typeof price.value.integer === "string" &&
                        price.value.integer.includes("$")
                        ? price.value.integer
                            .replace("$", "")
                            .split(".")[0]
                            .replace(",", "")
                        : price.value.integer.replace(",", "")
                      : 0,
                  decimal:
                    price.type !== "empty"
                      ? typeof price.value.decimal === "string" &&
                        price.value.decimal.includes("$")
                        ? price.value.decimal.replace("$", "").split(".")[0]
                        : price.value.decimal
                      : 0
                }}
              />
            )} 
          </div> */}
          <div className={classes.wishItemActionWrapper}>
            <button
              className={classes.wishItemRemove}

              onClick={event =>
                handleDeleteItemFromWishList(event, itemid, dispatch)
              }
            >
              <i title="Remove" class="material-icons-outlined">
                delete
              </i>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      onClick={close}
      className={
        `${langCode === "ar" ? classes.arabic + " " : ""}` +
        classes.outerContainer +
        `${show ? " " + classes.active : ""}`
      }
    >
      <div
        className={classes.container + `${show ? " " + classes.active : ""}`}
      >
        <div
          className={
            langCode === "ar"
              ? "wishListTitleArabic " + classes.wishListTitle
              : classes.wishListTitle
          }
        >
          <h6 className="minicart-title">{translate("wishlistMobile.title")} <i style={{fontSize : "20px", cursor: "pointer"}} className="material-icons" onClick={close}>
            close
          </i>  </h6>
          
        </div>

        <div className={classes.wrapper}>
          <div className={classes.wishListWrapper + ` scroll-bar-thin-style`}>
            {renderWishList()}
            {/* {
              wishListState.length > 5
                ? (
                  <div style={{ width: "80%", margin: "0 auto 30px auto", display: "flex" }}><Link to="/wishlist" style={{
                    padding: "4px 10px",
                    textTransform: "uppercase",
                    color: "#fff"
                  }} className="addToCartBtn">View More</Link></div>) : null} */}

           
          </div>

        </div>
      </div>
    </div>
  );
}
