/* Copyright 2020 Avetti.com Corporation - All Rights Reserved

This source file is subject to the Avetti Commerce Front End License (ACFEL 1.20)
that is accessible at https://www.avetticommerce.com/license */
import React, { useState, useContext } from "react";
import { Link } from "gatsby";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { VID, PROJECT_LINK, PREVIEW } from "../../project-config.js";
import ProfileIcon from "@assets/img/Profile.png";
import Modal from "@/shared/components/UIElements/Modal";
import Drawer from "@material-ui/core/Drawer";
import LazyLoad from "react-lazyload";
import Login from "@components/AC-LoginBox/Login";
import { I18nContext } from "@/i18n";
import ReactTooltip from 'react-tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { handleLogout } from "@/redux/actions/loginActions.js";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Profile({ modalOpenedByIcon, setModalOpened }) {
  const dispatch = useDispatch();
  const { langCode, translate } = useContext(I18nContext);

  const [openSnack, setOpenSnack] = React.useState(false);


  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const [Loginstate, LoginsetState] = useState({
    top1: false,
    left1: false,
    bottom1: false,
    right1: false
  });

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const [AfterLoginstate, AfterLoginsetState] = useState({
    top1: false,
    left1: false,
    bottom1: false,
    right1: false
  });

  const toggleDrawerLogin = (side, open) => event => {
    if (event.key === "Tab" || event.key === "Shift") {
      return;
    }

    LoginsetState({ ...Loginstate, [side]: open });
  };

  const toggleDrawerAfterLogin = (side, open) => event => {
    if (event.key === "Tab" || event.key === "Shift") {
      return;
    }

    AfterLoginsetState({ ...AfterLoginstate, [side]: open });
  };

  const sideListLogin = side => (
    <div role="presentation">
      <Login />
    </div>
  );

  const securityTokenState = useSelector(
    state => state.loginReducer.securityToken,
    shallowEqual
  );
  const loginNameState = useSelector(
    state => state.loginReducer.firstName,
    shallowEqual
  );

  const languageState = useSelector(
    state => state.mainReducer.lang,
    shallowEqual
  );

  const logout = e => {
    e.preventDefault();
    dispatch(handleLogout(securityTokenState));
    setOpenSnack(true)
  };

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(modalOpenedByIcon);
  }, [modalOpenedByIcon]);

  React.useEffect(() => {
    setModalOpened(open);
  }, [open]);

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={openSnack}
        className="loginSnack"
        autoHideDuration={5000}
        onClose={handleCloseSnack}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          Logged out Successfully
        </Alert>
      </Snackbar>
      {
        loginNameState != "" ? (
          <LazyLoad height={18} offset={100} debounce={300} fadein={true} style={{ display: "flex", alignItems: "center" }}>
            <div
              id="login-icon-btn"
              style={isMobileState ? { display: "none" } : null}
              className="icon-wrapper"
              onClick={
                langCode === "ar"
                  ? toggleDrawerAfterLogin("left", true)
                  : toggleDrawerAfterLogin("right", true)
              }
            >
              <img src="https://ik.imagekit.io/ofb/dbz/Profile_OGdd-nNG8Oc.svg?updatedAt=1639484485050" />
            </div>
            <div
              id="login-icon-btn"
              className="login-text-container"
              onClick={
                langCode === "ar"
                  ? toggleDrawerAfterLogin("left", true)
                  : toggleDrawerAfterLogin("right", true)
              }
            >
              <span
                data-background-color="#3F57C6" data-tip={`My Account`} data-class="datatool" data-offset="{'top': 10, 'left': 10}"
                className="icon-action-text"
                onClick={
                  langCode === "ar"
                    ? toggleDrawerAfterLogin("left", true)
                    : toggleDrawerAfterLogin("right", true)
                }
                title={loginNameState}
              >
                {loginNameState}
              </span>
              <ReactTooltip place="bottom" type="dark" effect="float" />
            </div>

            <Drawer
              className="loginState"
              anchor={langCode === "ar" ? "left" : "right"}
              open={langCode === "ar" ? AfterLoginstate.left : AfterLoginstate.right}
              onClose={
                langCode === "ar"
                  ? toggleDrawerAfterLogin("left", false)
                  : toggleDrawerAfterLogin("right", false)
              }
            >
              <div className={langCode == "ar" ? "inner arabic" : "inner"}>
                <div style={{ backgroundColor: "rgb(241, 241, 241)", color: "rgb(85, 85, 85)" }}>
                  <h6 className="minicart-title CustomerLogin">
                    {" "}
                    MyAccount
                    <i
                      onClick={
                        langCode === "ar"
                          ? toggleDrawerAfterLogin("left", false)
                          : toggleDrawerAfterLogin("right", false)
                      }
                      className="material-icons"
                      style={{ cursor: "pointer", fontSize: "20px", position: "absolute", right: "10px" }}
                    >
                      close
                    </i>
                  </h6>
                </div>
                <ul className="user-profile-dropdown">
                  {/* <a
                    href={`${PROJECT_LINK}/myaccount.html?mode=changepassword&vid=${VID}&iu=${languageState}`}
                  >
                    <li className="myaccount-box">
                      <i className="material-icons">vpn_key</i>{" "}
                      {translate("jsp.header_changepassword")}
                    </li>
                  </a> */}

                  {/* <a href={`${PROJECT_LINK}/myaccount.html?mode=messages&vid=${VID}&iu=${languageState}`}>
                    <li className="myaccount-box">
                      <i className ="material-icons">message</i>
                      Messages
                    </li>
                  </a> */}

                  <Link to={`/my-account/my-quote-requests`}>
                    <li className="myaccount-box">
                      <i className="material-icons">visibility</i>{" "}
                      View Quotes
                    </li>
                  </Link>
                  <Link to={`/my-account/notifications/`}>
                    <li className="myaccount-box">
                      <i className="material-icons">notifications</i>{" "}
                      {translate("jsp.header_notifications")}
                    </li>
                  </Link>
                  {/* <a
                    href={`${PROJECT_LINK}/myaccount.html?mode=billingaddress&vid=${VID}&iu=${languageState}`}
                  >
                    <li className="myaccount-box">
                      <i className="material-icons">store_mall_directory</i>{" "}
                      {translate("jsp.header_billingaddress")}
                    </li>
                  </a>
                  <a
                    href={`${PROJECT_LINK}/myaccount.html?mode=shippingaddress&vid=${VID}&iu=${languageState}`}
                  >
                    <li className="myaccount-box">
                      <i className="material-icons">local_shipping</i>{" "}
                      {translate("jsp.header_shippingaddress")}
                    </li>
                  </a> */}
                  <a href="javascript:void(0);">
                    <li className="myaccount-box">
                      <form name="logoutPopup" onSubmit={logout}
                        autoComplete="on">
                        <button
                         
                          onClick={
                            langCode === "ar"
                              ? toggleDrawerAfterLogin("left", false)
                              : toggleDrawerAfterLogin("right", false)
                          } type="submit">
                          <i className="material-icons">power_settings_new</i>{" "}
                          
                          {translate("jsp.header_logout")}
                        </button>
                        
                      </form>
                    </li>
                  </a>
                </ul>
              </div>
            </Drawer>
          </LazyLoad>
        ) : (
          <>
            <div
              id="login-icon-btn"
              className="icon-wrapper"
              onClick={
                langCode === "ar"
                  ? toggleDrawerLogin("left", true)
                  : toggleDrawerLogin("right", true)
              }
            >
              {!isMobileState ? <button className="login-btn">Sign Up / Sign In</button> : <button className="login-btn">Sign In</button>}

            </div>
            <Drawer
              className="loginState"
              anchor={langCode === "ar" ? "left" : "right"}
              open={langCode === "ar" ? Loginstate.left : Loginstate.right}
              onClose={
                langCode === "ar"
                  ? toggleDrawerLogin("left", false)
                  : toggleDrawerLogin("right", false)
              }
            >
              <div style={{ backgroundColor: "rgb(241, 241, 241)", color: "rgb(85, 85, 85)" }}>
                <h6 className="minicart-title CustomerLogin">
                  {translate("CustomerLogin")}
                  <i
                    onClick={
                      langCode === "ar"
                        ? toggleDrawerLogin("left", false)
                        : toggleDrawerLogin("right", false)
                    }
                    className="material-icons"
                    style={{ cursor: "pointer", fontSize: "20px", position: "absolute", right: "10px" }}
                  >
                    close
                  </i>
                </h6>
              </div>
              {langCode === "ar" ? sideListLogin("left") : sideListLogin("right")}
            </Drawer>
          </>
        )
      }
    </>
  );
}
