
import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useFormik } from "formik";
import { Box, Button, Icon, TextareaAutosize } from "@material-ui/core";
import * as Yup from "yup";
import { configQuoteRequest } from "../../../../../store-config";

const validationSchema = Yup.object().shape({
  message: Yup.string().trim().required("Required")
});



const MessageForm = ({
  showChatQuoteBasket,
  sendMessage,
  currentConversation,
  setCount,
  scrollToBottom,
  countShowConfirmForQuoteBasket,
  setStartCountdown,
  setShowConfirmForQuoteBasket,
  login,
  inputFIeldRef,
  setCurrentConversation,
  currentChat,
  classes,
  usingScrollToBottom
}) => {
  const formik = useFormik({
    initialValues: {
      message: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {

      if (showChatQuoteBasket) {
        sendMessage({
          variables: {
            chatId: currentConversation.id,
            content: values.message,
            senderId: currentConversation.chat_users.find(
              us => us.user.email === login.loginName
            ).user.id
          }
        })
          .then(() => {
            resetForm();
            setCount(2);

            if (usingScrollToBottom) scrollToBottom();

            if (configQuoteRequest === "CHAT") {
              if (countShowConfirmForQuoteBasket === 1) {
                setStartCountdown(true);
                setShowConfirmForQuoteBasket(true);
              }
            }
          })
          .catch(() => console.log("Error"));

      } else {
        sendMessage({
          variables: {
            chatId: currentConversation.id,
            content: values.message,
            senderId: currentConversation.chat_users.find(
              us => us.user.email === login.loginName
            ).user.id,
            quote_request_id: localStorage.getItem("quote_request_id") || null
          }
        })
          .then(() => {
            setCount(2);
            if (usingScrollToBottom) scrollToBottom();

            setCurrentConversation(currentChat);
            resetForm();
          })
          .catch(() => console.log("Error"));
      }
    }
  });

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 20
        }}
      >
        <TextareaAutosize
          ref={inputFIeldRef}
          autoFocus
          className={classes.chatInput}
          maxRows={4}
          name="message"
          placeholder={isMobileState ? "Type here..." : "Type your message here..."}
          value={formik.values.message}
          onChange={formik.handleChange}
          onKeyDown={event => {
            if (event.code === "Enter" || event.code === "NumpadEnter")
              formik.handleSubmit();
          }}
        />
        <Button
          variant="contained"
          style={{
            height: "max-content",
            background: "#FFFFFF",
            border: "3px solid #3f57c6",
            borderRadius: 30,
            padding: "10px 24px"
          }}
          endIcon={<Icon style={{ color: "#3f57c6" }}>send</Icon>}
          type="submit"
        >
          Send
        </Button>
      </Box>
    </form>
  );
};

export default MessageForm;
