/* Copyright 2020 Avetti.com Corporation - All Rights Reserved

This source file is subject to the Avetti Commerce Front End License (ACFEL 1.20)
that is accessible at https://www.avetticommerce.com/license */
import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";

import FooterContentFunc from "./footerContent";
import AnchorElement from "../../functions/AnchorElement.jsx";
import { useStaticQuery, graphql, Link } from "gatsby";
// import Img from "gatsby-image";
import Logo from "@assets/img/logo.jpg"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYoutube, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

import { I18nContext } from "../../i18n/index";
function Footer() {

  // const data = useStaticQuery(graphql`
  //   query {
  //     Logo: file(relativePath: { eq: "logo.jpg" }) {
  //       childImageSharp {
  //         fixed(width: 230) {
  //           ...GatsbyImageSharpFixed_withWebp
  //         }
  //       }
  //     }
  //   }
  // `);

  const { translate } = React.useContext(I18nContext);

  const langState = useSelector(state => state.mainReducer.lang, shallowEqual);

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const [footerContent, setFooterContent] = useState({});

  useEffect(() => {
    setFooterContent(FooterContentFunc(langState, loginNameState));
    return () => {
      setFooterContent({});
    };
  }, [langState]);

  const [activeFooterSection, setActiveFooterSection] = useState("");

  const renderLinks = arrContent => {
    return arrContent.map((content, i) => (
      <li {...content.parent.attributes} key={i}>
        {content.children.map((childContent, i) => {
          return <AnchorElement {...childContent} key={i} />;
        })}
      </li>
    ));
  };

  const finePrint = (
    <small>
      Copyright &copy; 2021 DIBIZ Corporation. All Rights Reserved
    </small>
  );
  if (footerContent && Object.keys(footerContent).length > 0) {
    if (isMobileState) {
      const handleOnClickFooterSection = e => {
        const { target: clickedFooterSectionTarget } = e.target.dataset;
        setActiveFooterSection(
          activeFooterSection !== clickedFooterSectionTarget &&
          clickedFooterSectionTarget
        );

        console.info(
          "activeFooterSection",
          activeFooterSection,
          clickedFooterSectionTarget
        );
      };

      const handleWhatIconToDisplay = footerSectionName => {
        return activeFooterSection === footerSectionName
          ? `expand_less`
          : `expand_more`;
      };
      return (
        <footer id="footer">
          <div className="new-footer" style={{ maxWidth: "100%" }}>
            <div className="columns " style={{ maxWidth: "100%" }}>
              <div className="footer_Line">
                <div>
                  <div className="footerLogo center">
                    <img src={Logo} alt="Footer Logo" />
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                  </div>
                </div>
              </div>
              {/* Mobile Version */}
              <div className="footer-container">
                <div
                  className="liHead first"
                  id="about-quickLinks"
                  onClick={e => handleOnClickFooterSection(e)}
                  data-target="quickLinks"
                  aria-controls="quickLinks"
                  aria-expanded={activeFooterSection === "quickLinks"}
                >
                  {/* {translate("vm.footer.learnmore")} */}
                  Quick Links
                  <i className="material-icons footer-icon" data-target="quickLinks">
                    {handleWhatIconToDisplay("quickLinks")}
                  </i>
                </div>
                <div
                  className="menu-footer"
                  aria-labelledby="about-quickLinks"
                  role="region"
                  id="quickLinks"
                  style={{
                    display: activeFooterSection === "quickLinks" ? "block" : "none"
                  }}
                >
                  <ul>{renderLinks(footerContent.quickLinks)}</ul>
                </div>
                <div
                  className="liHead first"
                  id="about-menu"
                  onClick={e => handleOnClickFooterSection(e)}
                  data-target="about"
                  aria-controls="about"
                  aria-expanded={activeFooterSection === "about"}
                >
                  {/* {translate("vm.footer.learnmore")} */}
                  Help
                  <i className="material-icons footer-icon" data-target="about">
                    {handleWhatIconToDisplay("about")}
                  </i>
                </div>
                <div
                  className="menu-footer"
                  aria-labelledby="about-menu"
                  role="region"
                  id="about"
                  style={{
                    display: activeFooterSection === "about" ? "block" : "none"
                  }}
                >
                  <ul>{renderLinks(footerContent.aboutMobile)}</ul>
                </div>

                <div
                  className="liHead"
                  id="shop-menu"
                  onClick={e => handleOnClickFooterSection(e)}
                  data-target="shop"
                  aria-controls="shop"
                  aria-expanded={activeFooterSection === "shop"}
                >
                  {/* {translate("vm.footer_contact")} */}
                  Contact
                  <i className="material-icons footer-icon" data-target="shop">
                    {handleWhatIconToDisplay("shop")}
                  </i>
                </div>
                <div
                  className="menu-footer"
                  id="shop"
                  aria-labelledby="shop-menu"
                  role="region"
                  style={{
                    display: activeFooterSection === "shop" ? "block" : "none"
                  }}
                >
                  {/* <ul>{renderLinks(footerContent.socialMedia)}</ul> */}
                  <ul>{renderLinks(footerContent.shop)}</ul>
                  <ul>
                    <li className="socialMedia">
                      <a target="_blank" href="https://www.facebook.com/dibizglobal" rel="noopener noreferrer">
                        <FontAwesomeIcon className="footerSocial fbb" icon={faFacebookF} />
                      </a>
                      <a target="_blank" href="https://www.linkedin.com/company/dibizglobal" rel="noopener noreferrer">
                        <FontAwesomeIcon className="footerSocial in" icon={faLinkedinIn} />
                      </a>
                      <a target="_blank" href="https://www.youtube.com/c/DIBIZGlobal" rel="noopener noreferrer">
                        <FontAwesomeIcon className="footerSocial yt" icon={faYoutube} />
                      </a>
                    </li>
                  </ul>
                </div>

                {/* <div
                  className="menu-footer"
                  id="socialMedia"
                  aria-labelledby="socialMedia-menu"
                  role="region"
                  style={{
                    display: "block"
                  }}
                >
                  <ul>{renderLinks(footerContent.socialMedia)}</ul>
                </div> */}
              </div>
              <div id="finePrint">{finePrint}</div>
            </div>
          </div>
        </footer>
      );
    } else {
      return (
        <footer id="footer">
          <div className="new-footer" style={{ maxWidth: "100%" }}>
            <div className="columns " style={{ maxWidth: "100%" }}>
              <div className="footer-container">
                <div className="menu-footer" id="socialMedia">
                  <div className="footerLogo pull-left">
                    <img src={Logo} alt="DIBIZ Logo" />
                    {/* <ul>{renderLinks(footerContent.socialMedia)}</ul> */}
                    <ul>
                      <li className="socialMedia">
                        <a target="_blank" href="https://www.facebook.com/dibizglobal" rel="noopener noreferrer">
                          <FontAwesomeIcon className="footerSocial fbb" icon={faFacebookF} />
                        </a>
                        <a target="_blank" href="https://www.linkedin.com/company/dibizglobal" rel="noopener noreferrer">
                          <FontAwesomeIcon className="footerSocial in" icon={faLinkedinIn} />
                        </a>
                        <a target="_blank" href="https://www.youtube.com/c/DIBIZGlobal" rel="noopener noreferrer">
                          <FontAwesomeIcon className="footerSocial yt" icon={faYoutube} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="liHead" id="about-menu">
                  {translate("vm.footer.learnmore")}
                  <i
                    className="hidden-md hidden-lg hidden-sm fas fa-plus-circle global--one-column pull-right"
                    id="global--mobile-about-expand"
                  ></i>
                </div> */}
                <div className="menu-footer" id="quickLinks">
                  <h3>Quick Links</h3>
                  <ul>{renderLinks(footerContent.quickLinks)}</ul>
                </div>
                <div className="menu-footer" id="about">
                  <h3>Help</h3>
                  <ul>{renderLinks(footerContent.about)}</ul>
                </div>
                {/* <div className="liHead" id="shop-menu">
                  {translate("vm.footer_contact")}
                  <i
                    className="hidden-md hidden-lg hidden-sm fas fa-plus-circle global--one-column pull-right"
                    id="global--mobile-shop-expand"
                  ></i>
                </div> */}
                <div className="menu-footer" id="shop">
                  <h3>Contact Us</h3>
                  <ul>{renderLinks(footerContent.shop)}</ul>
                </div>
                <div className="liHead" id="socialMedia-menu">
                  {translate("socialMedia")}
                  <i
                    className="hidden-md hidden-lg hidden-sm fas fa-plus-circle global--one-column pull-right"
                    id="global--mobile-socialMedia-expand"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div className="finePrintWrapper">
            <div className="finePrintLinks">
              <a href="https://dibizglobal.com/privacy-policy/">Privacy Policy</a>
              <a href="https://dibizglobal.com/terms-condition/">Terms Of Use</a>
            </div>
            <div id="finePrint">{finePrint}</div>
          </div>
        </footer>
      );
    }
  } else {
    return null;
  }
}

export default Footer;
