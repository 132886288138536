import React, { useContext, useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import Drawer from "@material-ui/core/Drawer";
import { PROJECT_LINK, VID } from "../../project-config";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { I18nContext } from "../../i18n";
import Login from "../AC-LoginBox/Login";
//import { useIntl } from "gatsby-plugin-react-intl";
import { handleLogout } from "../../redux/actions/loginActions";

const LoginProfileModal = ({ modalState }) => {
  const { langCode } = useContext(I18nContext);
  const dispatch = useDispatch();
  //const intl = useIntl();

  const [Loginstate, LoginsetState] = useState({
    top1: false,
    left1: false,
    bottom1: false,
    right1: false
  });

  const [AfterLoginstate, AfterLoginsetState] = useState({
    top1: false,
    left1: false,
    bottom1: false,
    right1: false
  });

  useEffect(() => {
    if (loginNameState == "" && modalState?.side) {
      LoginsetState({ ...Loginstate, [modalState?.side]: modalState?.open });
    } else {
      AfterLoginsetState({
        ...AfterLoginstate,
        [modalState?.side]: modalState?.open
      });
    }
  }, [modalState]);

  const loginNameState = useSelector(
    state => state.loginReducer.firstName,
    shallowEqual
  );
  const languageState = useSelector(
    state => state.mainReducer.lang,
    shallowEqual
  );
  const securityTokenState = useSelector(
    state => state.loginReducer.securityToken,
    shallowEqual
  );

  const toggleDrawerLogin = (side, open) => event => {
    console.log(side, open);
    if (event.key === "Tab" || event.key === "Shift") {
      return;
    }

    LoginsetState({ ...Loginstate, [side]: open });
  };

  const toggleDrawerAfterLogin = (side, open) => event => {
    if (event.key === "Tab" || event.key === "Shift") {
      return;
    }

    AfterLoginsetState({ ...AfterLoginstate, [side]: open });
  };

  const sideListLogin = side => (
    <div role="presentation">
      <Login />
    </div>
  );

  const logout = e => {
    e.preventDefault();
    dispatch(handleLogout(securityTokenState));
  };

  return loginNameState != "" ? (
    <Drawer
      className="loginState"
      anchor={langCode === "ar" ? "left" : "right"}
      open={langCode === "ar" ? AfterLoginstate.left : AfterLoginstate.right}
      // open={true}
      onClose={
        langCode === "ar"
          ? toggleDrawerAfterLogin("left", false)
          : toggleDrawerAfterLogin("right", false)
      }
    >
      <div className={langCode == "ar" ? "inner arabic" : "inner"}>
        <div style={{ backgroundColor: "rgb(241, 241, 241)", boxShadow: "0 0 5px #cdcdcd" }}>
          <h6 className="minicart-title CustomerLogin">
            {" "}
            My Account
            <i
              onClick={
                langCode === "ar"
                  ? toggleDrawerAfterLogin("left", false)
                  : toggleDrawerAfterLogin("right", false)
              }
              className="material-icons"
              style={{
                cursor: "pointer",
                fontSize: "20px",
                position: "absolute",
                right: "10px"
              }}
            >
              close
            </i>
          </h6>
        </div>
        <ul className="user-profile-dropdown">
          <a
            href={`${PROJECT_LINK}/myaccount.html?mode=changepassword&vid=${VID}&iu=${languageState}`}
          >
            <li className="myaccount-box">
              <i className="material-icons">vpn_key</i>{" "}
              Change Password
            </li>
          </a>

          <a
            href={`${PROJECT_LINK}/myaccount.html?mode=vieworder&vid=${VID}&iu=${languageState}`}
          >
            <li className="myaccount-box">
              <i className="material-icons">visibility</i>{" "}
              View Orders
            </li>
          </a>
          <a
            href={`${PROJECT_LINK}/myaccount.html?mode=activities&vid=${VID}&iu=${languageState}`}
          >
            <li className="myaccount-box">
              <i className="material-icons">notifications</i>{" "}
              Notifications
            </li>
          </a>
          <a
            href={`${PROJECT_LINK}/myaccount.html?mode=billingaddress&vid=${VID}&iu=${languageState}`}
          >
            <li className="myaccount-box">
              <i className="material-icons">store_mall_directory</i>{" "}
              Billing Address
            </li>
          </a>
          <a
            href={`${PROJECT_LINK}/myaccount.html?mode=shippingaddress&vid=${VID}&iu=${languageState}`}
          >
            <li className="myaccount-box">
              <i className="material-icons">local_shipping</i>{" "}
              Shipping Address
            </li>
          </a>
          <a href="javascript:void(0);">
            <li className="myaccount-box">
              <form name="logoutPopup" onSubmit={logout} autoComplete="on">
                <button type="submit">
                  <i className="material-icons">power_settings_new</i>{" "}
                  Logout
                </button>
              </form>
            </li>
          </a>
        </ul>
      </div>
    </Drawer>
  ) : (
    <Drawer
      className="loginState"
      anchor={langCode === "ar" ? "left" : "right"}
      open={langCode === "ar" ? Loginstate.left : Loginstate.right}
      // open={true}
      onClose={
        langCode === "ar"
          ? toggleDrawerLogin("left", false)
          : toggleDrawerLogin("right", false)
      }
    >
      <div style={{ backgroundColor: "rgb(241, 241, 241)", boxShadow: "0 0 5px #cdcdcd" }}>
        <h6 className="minicart-title CustomerLogin">
          Login
          <i
            onClick={
              langCode === "ar"
                ? toggleDrawerLogin("left", false)
                : toggleDrawerLogin("right", false)
            }
            className="material-icons"
            style={{
              cursor: "pointer",
              fontSize: "20px",
              position: "absolute",
              right: "10px"
            }}
          >
            close
          </i>
        </h6>
      </div>
      {langCode === "ar" ? sideListLogin("left") : sideListLogin("right")}
    </Drawer>
  );
};

export default LoginProfileModal;
