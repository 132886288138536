/* Copyright 2020 Avetti.com Corporation - All Rights Reserved

This source file is subject to the Avetti Commerce Front End License (ACFEL 1.20)
that is accessible at https://www.avetticommerce.com/license */
import React, { useState, useContext, useEffect, useMemo } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { VID, PROJECT_LINK } from "../../project-config.js";
import { Link } from "gatsby";
import { configQuoteRequest } from "../../../store-config.js";
import NavMenu from "./NavMenu.jsx";
import Drawer from "@material-ui/core/Drawer";
import MiniCart from "@components/AC-MiniCart/MiniCart.jsx";
import ReactTooltip from 'react-tooltip';
import {
  setBasketMode,
  setSupplierBasket
} from "../../redux/actions/basketActions.js";
import Profile from "./Profile.jsx";
import { setHTMLElementFixedPosition } from "@/functions/Utilities.js";
import SearchBarMobile from "@components/AC-Search/components/SearchBarMobile";
import WishList from "@components/AC-WishList/components/WishListCustomModal.jsx";
import { I18nContext } from "@/i18n/index";
import axios from "axios";
import Async from "react-code-splitting";
import { fetchWishList } from "../../redux/actions/wishListActions.js";
import { fetchingMenuSuccess } from "@/redux/actions/menuActions.js";
import { handleMobileOrDesktop } from "@/redux/actions/mainActions.js";
import { FormattedNumber } from "react-intl";
import { updateBrandsGeolocation } from "@/redux/actions/geoLocationActions.js";
import logo from "@assets/img/logo.jpg";

const SearchBar = () => <Async load={import("./SearchHelperHeader")} />;

export default function MenuTopBar({ data, brands }) {
  const dispatch = useDispatch();
  const { translate, currency, priceConvert, dispatchContext } = useContext(I18nContext);

  const isBrowser = typeof window !== "undefined";
  const [pathname, SetPathname] = useState(null);

  useEffect(() => {
    const navCats = {
      name: "Shop",
      vid: "20210820389",
      cid: "529905",
      thumbnail: "No Image",
      image: "No Image",
      position: "1",
      description: "Shop",
      metadescription:
        "This is a Demo Item. This product demonstrates item options. As options on the right side of the Micro Vault Midi, as an entry-level portable storage solution, is ideally suited to plug into a laptop",
      metakeywords: "Lenovo IdeaPad S110 Golf Shirts Sony Micro Vault Midi 4GB",
      URL: "shop/system/shop",
      childs: [...data]
    };
    dispatch(fetchingMenuSuccess(navCats));
    dispatch(fetchWishList());
    //BRANDS GEOLOCATION
    dispatch(updateBrandsGeolocation(brands));
    if (isBrowser) {
      SetPathname(window.location.pathname)
    }
  }, []);
  const langCode = useSelector(state => state.mainReducer.lang, shallowEqual);
  const [numberOfNotifications, setNumberOfNotifications] = useState(0);
  const [loginModalOpened, setLoginModalOpened] = useState(false);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const suppliersBasketState = useSelector(
    state => state.sessionReducer.suppliersBasket,
    shallowEqual
  );

  const handleViewCartClicked = () => {
    const button = document.getElementById("quote-cart-icon-btn");
    button.click();
    handleClose();
  };

  const securityTokenState = useSelector(
    state => state.loginReducer.securityToken,
    shallowEqual
  );

  const loginNameState = useSelector(
    state => state.loginReducer.firstName,
    shallowEqual
  );

  useEffect(async () => {
    if (securityTokenState) {
      const { data } = await axios(`${PROJECT_LINK}/notifications.ajx?vid=${VID}&iu=en`);
      setNumberOfNotifications(data.count);
    }
  }, [securityTokenState]);

  const suppliersBasketCountState =
    suppliersBasketState &&
    Object.keys(suppliersBasketState).length > 0 &&
    Object.keys(suppliersBasketState).reduce((a, key) => {
      a += suppliersBasketState[key].products.length;
      return a;
    }, 0);

  const quoteProducts = useMemo(() => {
    if (suppliersBasketState) {
      const quoteProducts = Object.keys(suppliersBasketState).reduce(
        (arr, vid) => {
          arr.push(...suppliersBasketState[vid].quoteproducts);
          return arr;
        },
        []
      );

      return quoteProducts;
    } else return [];
  }, [suppliersBasketState]);

  const quoteProductsSubTotal = useMemo(() => {
    if (quoteProducts) {
      const sumOfAllQuoteProducts = quoteProducts.reduce((acc, product) => {
        acc += product.price * product.qty;
        return acc;
      }, 0);

      return (
        <FormattedNumber
          value={sumOfAllQuoteProducts / priceConvert}
          style="currency"
          currency={currency}
        >
          {value => <b style={{ fontSize: "18px" }}>{value}</b>}
        </FormattedNumber>
      );
    }
  }, [quoteProducts]);

  /*  const basketState = useSelector(
    state => state.sessionReducer.basket,
    shallowEqual
  );

  const basketCountState = basketState && basketState.itemsCount;
 */
  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );
  const languageState = useSelector(
    state => state.mainReducer.lang,
    shallowEqual
  );

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );



  //#region screen width listener starts
  let initialMobile;
  if (typeof window !== "undefined") {
    initialMobile = window.innerWidth;
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'basket-popover' : undefined;

  const [currentScreenWidth, setCurrentScreenWidth] = useState(initialMobile);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleResize = () => {
        setCurrentScreenWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  let isMobile;
  const mobileSize = 768;
  useEffect(() => {
    if (currentScreenWidth > mobileSize) {
      isMobile = false;
    } else {
      isMobile = true;
    }
    dispatch(handleMobileOrDesktop({ isMobile, currentScreenWidth }));
  }, [currentScreenWidth]);

  //#endregion screen width listener starts

  let name;

  const toggleDrawerMini =
    (side, open, type = "products") =>
      event => {
        if (event.key === "Tab" || event.key === "Shift") {
          return;
        }
        dispatch(setBasketMode(type));
        setState({ ...state, [side]: open });
      };

  const sideList = side => (
    <div role="presentation" onClick={toggleDrawerMini(side, false)}>
      <MiniCart close={() => toggleDrawerMini(side, false)} />
    </div>
  );

  const [navMenuOpen, setNavMenuOpen] = useState(false);

  const toggleDrawer = event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setNavMenuOpen(!navMenuOpen);
  };

  // const toggleDrawersearch = () => event => {
  //   if (
  //     event &&
  //     event.type === "keydown" &&
  //     (event.key === "Tab" || event.key === "Shift")
  //   ) {
  //     return;
  //   }

  //   setNavMenuOpen(!navMenuOpen);
  // };

  useEffect(() => {
    setHTMLElementFixedPosition(navMenuOpen);
    return () => {
      setHTMLElementFixedPosition(false);
    };
  }, [navMenuOpen]);

  const toggleMenu = (cid) => {
    document.getElementById(cid).classList.toggle("showMenu");
    setFlag(!flag)
  }

  const handleCategoryChange = (cid, cat, parents, longdesc) => {
    // let catName = cat;
    if (isMobileState) {
      setNavMenuOpen(!navMenuOpen);
    }
    /*   console.info("handleCatChange", cid, cat, parents);
    if (cat.includes("&amp;")) {
      catName = cat.replace("&amp;", "&");
    }

    const category = navCatsState.childs.filter(c => {
      let name = c.name;
      if (name.includes("&amp;")) {
        name = name.replace("&amp;", "&");
      }

      return name.toLowerCase() === catName.toLowerCase();
    });
    //dispatch(changeCategoryName(catName));

    if (cid != "72167" && cid != "72165") {
      // 432381 - stores // cat//Stores
      // dispatch(fetchCategoryFromRender(cid, catName, parents, "", category[0]));
    } */
  };

  const handleOpenModalToChangeDistance = () => {
    const button = document.getElementById("locationChangeBtn");
    button.click();
  };

  const renderMyCartTextOrTheSumOfTheCart = () => {
    if (suppliersBasketCountState > 0) {
      let sumOfAllProducts = Object.keys(suppliersBasketState).reduce(
        (a, key) => {
          a += suppliersBasketState[key].products.reduce((acc, product) => {
            acc += product.price * product.qty;
            return acc;
          }, 0);
          return a;
        },
        0
      );

      return (
        <FormattedNumber
          value={sumOfAllProducts / priceConvert}
          style="currency"
          currency={currency}
        >
          {value => <b style={{ fontSize: "18px" }}>{value}</b>}
        </FormattedNumber>
      );
    }
    return translate("js.header.cart");
  };
  return (
    <>
      <nav className="top-menu">
        <Link to="/buyers" className="top-menu_link">For Buyers</Link>
        <span className="top-menu_divider"></span>
        <Link to="/sellers" className="top-menu_link">For Sellers</Link>
      </nav>
      <nav
        className="nav-extended"
        style={{ background: "#fff", padding: "0px 0px", zIndex: "99" }}
      >
        <div className="nav-wrapper">
          <div className="header-container" style={{ height: "auto" }}>
            {isMobileState ? (
              <React.Fragment>
                <div className="icon-container">
                  <div className="icon-wrapper">
                    <i
                      id="mobile-nav-icon"
                      open={navMenuOpen}
                      className="material-icons mobile-nav-icon"
                      onClick={() => {
                        toggleDrawer();
                      }}
                    >
                      <span></span>
                    </i>
                  </div>
                </div>
                <div className="mobile-nav-bar-outer-container">
                  <div className="mobile-nav-bar-container" open={navMenuOpen}>
                    <div
                      id="nav-menu-drawer"
                      className={
                        langCode === "ar"
                          ? "rtl nav-wrapper actual-nav scroll-bar-thin-style"
                          : "nav-wrapper actual-nav scroll-bar-thin-style"
                      }
                    >
                      <div className="nav-content-wrapper">
                        <div className="search-bar-container">
                          <div className="search-bar-wrapper">
                            <SearchBarMobile navMenuOpen={navMenuOpen} />
                          </div>
                        </div>
                        <ul className="staticMenu">
                          {/* <li style={{ color: "#2aa841" }}>
                            <Link
                              onClick={() => {
                                if (isMobileState) {
                                  setNavMenuOpen(!navMenuOpen);
                                }
                              }}
                              className="menuCat1"
                              to={"/"}
                            >
                              {translate("Home").toUpperCase()}
                            </Link>
                          </li> */}

                          {data.map(cat => {
                            let catName = cat.description.replace("Our Store", "Our Stores");
                            let tempUrl = cat.URL;
                            tempUrl = tempUrl;

                            tempUrl = tempUrl.replace(
                              `${langCode}/shop`,
                              "shop"
                            );

                            tempUrl = tempUrl.replace(`shop`, "").replace("/contact-us", "https://dibizglobal.com/contact/");
                            return (
                              <li key={cat.cid}>
                                <Link
                                  onClick={() =>
                                    handleCategoryChange(
                                      cat.cid,
                                      catName,
                                      [[catName, cat.cid, cat.URL]],
                                      ""
                                    )
                                  }
                                  className="menuCat dropdown-btn-mobile"
                                  style={{
                                    textTransform:
                                      catName != "by Brand" &&
                                        catName != "by Style"
                                        ? ""
                                        : null
                                  }}
                                  to={tempUrl}
                                >
                                  {console.info("tempUrl", tempUrl)}

                                  {catName == "Manufacturers" ?
                                      <span
                                        className=
                                          {
                                            loginNameState ? "displayManufacturers" : "hideManufacturers"
                                          }

                                        dangerouslySetInnerHTML={{
                                          __html: catName.toLowerCase(),
                                        }}
                                        style={{ textTransform: "capitalize" }}
                                      /> : <span
                                      className="ddd"
                                      dangerouslySetInnerHTML={{
                                        __html: catName.toLowerCase(),
                                      }}
                                      style={{ textTransform: "capitalize" }}
                                    />}

                                  {/* <span
                                    dangerouslySetInnerHTML={{
                                      __html: catName
                                    }}
                                  /> */}
                                </Link>

                                {
                                  cat.childs && cat.childs.length > 0 && (
                                    <>
                                      {
                                        !flag ? (
                                          <i className="material-icons" onClick={() => toggleMenu(cat.cid)}>
                                            keyboard_arrow_right
                                          </i>
                                        )
                                          :
                                          (
                                            <i className="material-icons" onClick={() => toggleMenu(cat.cid)}>
                                              keyboard_arrow_down
                                            </i>
                                          )
                                      }
                                    </>

                                  )
                                }

                                {
                                  cat.childs && cat.childs.length > 0 && (
                                    <ul id={`${cat.cid}`} className="dropdown-content-mobile">
                                      {cat.childs.map((subcat, index) => (
                                        <li className="subchilds1" key={index}>
                                          <Link
                                            to={"/" + subcat.URL.replace("shop/", "")}
                                            onClick={() => handleCategoryChange()}
                                          >
                                            <p>{subcat.description}</p>
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  )
                                }
                              </li>
                            );
                          })}
                        </ul>
                        <ul className="staticMenu">
                          <li>
                            <a
                              href="https://dibizglobal.com/about/"
                              target="_blank"
                            >
                              {translate("vm.footer_about")}
                            </a>
                          </li>
                          <li
                            onClick={() => {
                              toggleDrawer();
                              const btn = document.getElementById(
                                "login-icon-btn"
                              );
                              if (btn) btn.click();
                            }}
                          >
                            <span>
                              {loginNameState
                                ? loginNameState
                                : translate("js.header.login")}

                            </span>
                            <i className="material-icons">keyboard_arrow_right</i>
                          </li>

                          {/* <li
                            onClick={() => {
                              toggleDrawer();
                              const btn = document.getElementById(
                                "cart-icon-btn"
                              );
                              if (btn) btn.click();
                            }}
                          >
                            <span>MY CART</span>
                          </li>
                          <li
                            onClick={() => {
                              toggleDrawer();
                              const btn = document.getElementById(
                                "wishlist-icon-btn"
                              );
                              if (btn) btn.click();
                            }}
                          >
                            <span>MY WISHLIST</span>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : null}

            <div className="logo-container">
              <Link to={"/"} className="brand-logo logo-i">
                <div className="menu-logo-wrapper">
                  <img
                    src={logo}
                    className="img-responsive"
                    alt="DIBIZ Logo"
                  />
                </div>
              </Link>
            </div>






            {
              !isMobileState && !(pathname == "/") && (<SearchBar />)
            }

            {
              !isMobileState && (<NavMenu data={data} />)
            }

            <div
              onClick={() => {
                navMenuOpen && setNavMenuOpen(false);
              }}
              className="nav-icons-container"
            >
              <div className="nav-icons-container">

                {securityTokenState && (

                  <div className="icon-container shopping-bag-icon">
                    <div className="icon-wrapper">
                      <Link to="/my-account/notifications/" style={{ display: "flex" }} data-background-color="#3F57C6" data-tip="Quote Messages" data-class="datatool" data-offset="{'top': 10, 'left': 10}">
                        <img src="https://ik.imagekit.io/ofb/dbz/Chat_vWLqVd1KQ.svg?updatedAt=1639484484588" />
                      </Link>
                      {
                        !isMobileState && (
                          <span
                            className="miniwishlist-new-count"
                          >
                            {numberOfNotifications > 99 ? "99+" : numberOfNotifications}
                          </span>
                        )
                      }
                    </div>
                  </div>
                )}

                {
                  loginNameState !== "" && (<WishList />)
                }

                {
                  loginNameState !== "" && (
                    <div
                      className="icon-container login-icon-container"
                    >
                      {
                        isMobileState ? 
                        <div className="icon-wrapper">
                        <span onClick={handleViewCartClicked}>
                          <img src="https://ik.imagekit.io/ofb/dbz/Bag_HiovlJm5b.svg?updatedAt=1639484484463" alt="Icon" />
                        </span>
                      </div> : 
                      <div className="icon-wrapper" data-background-color="#3F57C6" data-tip="Quote Basket" data-class="datatool" data-offset="{'top': 10, 'left': 10}">
                      <span onClick={handleViewCartClicked}>
                        <img src="https://ik.imagekit.io/ofb/dbz/Bag_HiovlJm5b.svg?updatedAt=1639484484463" alt="Icon" />
                      </span>
                    </div>
                      }
                    </div>
                  )
                }



                <div
                  className={!isMobileState ? "icon-container login-icon-container" : "login-icon-container"}
                >
                  <Profile
                    modalOpenedByIcon={loginModalOpened}
                    setModalOpened={setLoginModalOpened}
                  />
                </div>


                {/* <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MiniCart />
              </Popover> */}



                {/* <div
                id="cart-icon-btn"
                className="icon-container shopping-bag-icon"
              >
                <div className="icon-wrapper">
                  <i className="material-icons">search</i>
                </div>
              </div> */}

                {/* <div className="icon-container">
                  {process.env.GATSBY_SUPPORTED_LANGUAGES.split(",").length >
                    1 && <LanguageSelect dispatchContext={dispatchContext} />}
                </div> */}


                {configQuoteRequest !== "NONE" && (
                  <div
                    id="quote-cart-icon-btn"
                    className="icon-containerr shopping-bag-icon"
                    onClick={
                      langCode === "ar"
                        ? toggleDrawerMini("left", true, "quoteproducts")
                        : toggleDrawerMini("right", true, "quoteproducts")
                    }
                  // onClick={() => {
                  //   window.location.href = `${LINK_DISTRIBUTION}/basket.html?vid=${VID}&ml=${langCode}&groupmode=quote`;
                  // }}

                  // onClick={() => {
                  //   dispatch(setSupplierBasket(keyVid));
                  //   navigate(`/quote/${keyVid}`);
                  // }}
                  >
                    {/* <div className="icon-wrapper" title="Quote Basket">
                     
                      <img src="https://ik.imagekit.io/ofb/ofb/dev/quote_basket_yDotEwb36ll.png" />
                      <span
                        className="minicart-new-count"
                        style={{
                          display: quoteProducts.length > 0 ? "" : "none"
                        }}
                      >
                        {quoteProducts.length}
                      </span>
                    </div> */}
                    {/* <div className="icon-text">
                      <span className="icon-action-text">
                        {quoteProductsSubTotal}
                      </span>
                    </div> */}
                  </div>
                )}



                <Drawer
                  anchor={langCode === "ar" ? "left" : "right"}
                  open={langCode === "ar" ? state.left : state.right}
                  onClose={
                    langCode === "ar"
                      ? toggleDrawerMini("left", false)
                      : toggleDrawerMini("right", false)
                  }
                >
                  {langCode === "ar" ? sideList("left") : sideList("right")}
                </Drawer>

                {/* <div
                id="cart-icon-btn"
                className="icon-container shopping-bag-icon"
                onClick={handleClick}
              >
                <div className="icon-wrapper">
                  <i className="material-icons">shopping_cart</i>
                  <span
                    className="minicart-new-count"
                    style={{
                      display: suppliersBasketCountState ? "" : "none",
                    }}
                  >
                    {suppliersBasketCountState || 0}
                  </span>
                </div>
              </div> */}

                {/*  */}

              </div>
              </div>
            </div>
        </div>
      </nav>


      {/* <nav
        className={isMobileState ? "nav-extended hidden" : "nav-extended"}
        style={{
          boxShadow: "none",
          background: "#c2ff61",
          padding: "5px 0px",
          borderTop: "2px solid #000"
        }}
      >
        <div className="nav-wrapper">
          <div className="header-container">
            <NavMenu data={data} />
            <SearchBar />
          </div>
        </div>
      </nav> */}
    </>
  );
}
