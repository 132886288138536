import { useSubscription } from "@apollo/client";
import { makeStyles, Modal } from "@material-ui/core";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  hideChatPopup,
  hideChatQuoteBasket
} from "../../../../redux/actions/chatActions";
import Chat from "./Chat";

const useStyles = makeStyles(() => ({
  chatWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const ChatModal = ({ params }) => {
  const showChatPopup = useSelector(
    state => state.chatReducer.showChatPopup,
    shallowEqual
  );
  const dispatch = useDispatch();

  const classes = useStyles();

  const handleCloseModal = () => {
    dispatch(hideChatQuoteBasket());
    dispatch(hideChatPopup());
  };

  return (
    <Modal
      open={showChatPopup}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.chatWrapper}
    >
      <Chat params={params} showChatPopup={showChatPopup} />
    </Modal>
  );
};

export default ChatModal;
