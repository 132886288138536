import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import CancelIcon from "@material-ui/icons/Cancel";
import Divider from "@material-ui/core/Divider";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  hideChatPopup,
  hideChatQuoteBasket
} from "../../../../redux/actions/chatActions";
import { useMutation, useSubscription } from "@apollo/client";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { GET_CHAT_SUBSCRIPTION, GET_USER_ONLINE } from "./queries";
import { SEND_MESSAGE } from "./mutations";
import { trimString } from "../../../../functions/Utilities";
import {
  configQuoteRequest,
  configSupportChat,
  configSupplierChat
} from "../../../../../store-config";
import tweet from "../../../../../src/assets/sounds/tweet.mp3";
import MessageDetail from "./MessageDetail";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";

const sound = typeof window !== "undefined" && new Audio(tweet);
const pathname = typeof window !== "undefined" && window.location.pathname;

const useStyles = makeStyles(theme => ({
  chatWrapperMessageDetail: {
    maxHeight: "75vh",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  messageContainer: {
    height: "500px",
    minHeight: "calc(100% - 93px)",
    maxHeight: "calc(100% - 93px)",
    // overflowY: "scroll",
    overflowX: "hidden",
    width: "100%",

    "& > div": {
      overflowY: "visible"
    }
  },
  chatItemMessageDetail: {},
  chatContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.papsendMessageLoadinger,
    overflow: "scroll"
  },
  chatContainerLeft: {
    height: "100%",
    overflow: "scroll",
    padding: 0,
    borderRight: "1px solid #f1f1f1",
    "-ms-overflow-style": "none" /* IE and Edge */,
    scrollbarWidth: "none",

    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  chatInput: {
    background: "#F7F9FA",
    border: "solid 1px #707070",
    borderRadius: 40,
    marginRight: 15,
    padding: "15px 30px 15px 30px"
  },
  chatOwnerContainer: {
    display: "flex",
    flexDirection: "row-reverse"
  },
  displayBlock: {
    fontWeight: "500",
    color: "#555"
  },
  chatOtherContainer: {
    display: "flex",
    flexDirection: "row"
  },
  chatOwnerText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  chatOwnerAvatar: {
    minWidth: "max-content",
    marginLeft: 15
  },
  textChatContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  chatPopupContainer: {
    flex: 1
  },
  chatPopupWrapper: {
    height: "max-content",
    minHeight: "80vh",
    background: "rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;",
    border: "1px solid #e8e6e6",
    //boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    display: "flex",
    flexDirection: "column"
  },
  listItemAvatar: {
    minWidth: "max-content",
    marginRight: 15
  },
  listItemAvatarChild: {
    // width: 30,
    // height: 30,
    marginRight: 10
  },
  chatWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  chatHeader: {
    position: "absolute",
    right: 0,
    display: "flex",
    justifyContent: "flex-end",
    margin: "10px"
  },
  chatHeaderMobile: {
    display: "flex",
    justifyContent: "space-between"
  },
  buttonViewMessageLabel: {
    fontSize: "0.6rem"
  },
  listSubHeader: {
    fontSize: "15px",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "600",
    textTransform: "uppercase",
    color: "#3F57C6",
    padding: "20px 20px 20px 20px",
    marginBottom: 5
  },
  listItem: {
    padding: "12px 20px"
  },
  divider: {
    background: "#f1f1f1",
    marginBottom: 0,
    marginTop: 0
  },
  avatar: {
    border: "3px solid #3F57C6",
    background: "#F6F6F6"
  },
  listItemText: {
    "& > .MuiListItemText-primary": {
      color: "#3F57C6",
      fontWeight: "600",
      fontSize: "17px"
    }
  }
}));

const StyledBadge = withStyles(theme => ({
  badge: {
    backgroundColor: "#3f57c6",
    color: "#3f57c6",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""'
    }
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0
    }
  }
}))(Badge);

const Chat = ({
  params,
  showChatPopup,
  usingChatPopup = true,
  usingBorderRadius = true,
  usingMarketplaceSupport = true,
  style,
  usingScrollToBottom = true
}) => {
  const supplierBasket = useSelector(
    state =>
      state.sessionReducer.suppliersBasket[
        params && typeof params.vid !== "undefined"
          ? params.vid
          : state.sessionReducer.selectedBasket
      ],
    shallowEqual
  );
  const showChatQuoteBasket = useSelector(
    state => state.chatReducer.showChatQuoteBasket,
    shallowEqual
  );
  const chatStore = useSelector(state => state.chatReducer, shallowEqual);

  const login = useSelector(state => state.loginReducer, shallowEqual);

  const activeDist = useSelector(
    state => state.chatReducer.activeDist,
    shallowEqual
  );

  const currentQuoteBasket = supplierBasket?.quoteproducts?.filter(
    item => item.distName === activeDist
  )?.[0];

  const [count, setCount] = useState(1);
  const [statusMarketplaceSupport, setStatusMarketplaceSupport] =
    useState(false);
  const [currentChatId, setCurrentChatId] = useState();
  const [supportAgentChat, setSupportAgentChat] = useState([]);
  const [currentConversation, setCurrentConversation] = useState();
  const [reRangeChatSubscription, setReRangeChatSubscription] =
    useState(chatSubscription);

  const { data: userOnlineSubscription } = useSubscription(GET_USER_ONLINE);
  const { data: chatSubscription, loading } = useSubscription(
    GET_CHAT_SUBSCRIPTION
  );

  const [sendMessage] = useMutation(SEND_MESSAGE);

  const inputFIeldRef = useRef();

  useEffect(() => {
    if (
      chatSubscription &&
      chatSubscription.chat &&
      currentConversation &&
      currentConversation.id
    ) {
      console.log("setCurrentConversation - 1");
      setCurrentConversation(
        chatSubscription.chat.find(el => el.id === currentConversation.id)
      );
    }

    setReRangeChatSubscription(chatSubscription);
  }, [chatSubscription]);

  const currentChat = chatSubscription?.chat?.find(el => {
    return chatStore?.chatWithVendor
      ? el.chat_users
          .filter(user => user.user.vendor !== null)
          .find(user => user.user.vendor.supplier_vendorId)?.user?.vendor
          ?.supplier_vendorId === chatStore?.chatWithVendor
      : el.id === currentChatId;
  });
  const supplierInfo = currentChat?.chat_users?.find(
    item => item.user.email !== login.loginName
  )?.user;

  useEffect(() => {
    if (chatStore.chatWithVendor) {
      if (count === 1 && showChatQuoteBasket && configQuoteRequest !== "NONE") {
        // setCurrentConversation(currentChat1)
        // sendMessage({
        //   variables: {
        //     chatId: currentChat?.id,
        //     senderId: supplierInfo?.id,
        //     content: `You have requested a quote for: ${
        //       currentQuoteBasket?.qty
        //     } ${currentQuoteBasket?.title} ${formatter.format(
        //       currentQuoteBasket?.price / priceConvert
        //     )} each`,
        //     quote_request_id: currentQuoteBasket?.id + ""
        //   }
        // }).then(() => {
        //   sendMessage({
        //     variables: {
        //       chatId: currentChat?.id,
        //       senderId: supplierInfo?.id,
        //       content: `While we wait to connect to ${supplierInfo?.vendor?.brand}  can you advise if you have any Additional Requirments for this quote request?`,
        //       quote_request_id: currentQuoteBasket?.id + ""
        //     }
        //   }).then(() => {
        //     const tempReRangeChatSubscription = chatSubscription?.chat
        //       ?.map(item => ({
        //         ...item,
        //         timestamp:
        //           item?.messages?.[item?.messages?.length - 1]?.created_at
        //       }))
        //       ?.sort((x, y) => moment(y?.timestamp).diff(x?.timestamp));
        //
        //     setCount(2);
        //     setCurrentConversation(currentChat);
        //     handleFocusInputField();
        //     setStatusMarketplaceSupport(false);
        //     setReRangeChatSubscription({
        //       ...reRangeChatSubscription,
        //       chat: tempReRangeChatSubscription
        //     });
        //     if (maxWidth958) setShowChatPopupDetail(true);
        //   });
        // });
      } else {
        const tempReRangeChatSubscription = chatSubscription?.chat
          ?.map(item => ({
            ...item,
            timestamp: item?.messages?.[item?.messages?.length - 1]?.created_at
          }))
          ?.sort((x, y) => moment(y?.timestamp).diff(x?.timestamp));

        setCurrentConversation(currentChat);
        setReRangeChatSubscription({
          ...reRangeChatSubscription,
          chat: tempReRangeChatSubscription
        });
      }
    }
  }, [
    chatStore.chatWithVendor,
    showChatPopup,
    chatSubscription,
    showChatQuoteBasket
  ]);

  useEffect(() => {
    if (
      (!chatStore?.chatWithVendor &&
        showChatPopup &&
        !currentChatId &&
        configSupplierChat === "ON" &&
        chatSubscription?.chat?.length > 0,
      supportAgentChat?.length > 0)
    ) {
      console.log("setCurrentConversation - 4");
      setCurrentConversation(supportAgentChat?.[0]);
      setStatusMarketplaceSupport(true);
      dispatch(hideChatQuoteBasket());
      handleFocusInputField();
      if (maxWidth958) setShowChatPopupDetail(true);
    }
  }, [
    showChatPopup,
    configSupplierChat,
    chatSubscription,
    pathname,
    currentChatId,
    supportAgentChat
  ]);

  const dispatch = useDispatch();

  const maxWidth958 = useMediaQuery("(max-width:958px)");

  const classes = useStyles();

  const [showChatPopupDetail, setShowChatPopupDetail] = useState(false);

  const handleCloseModal = () => {
    setCurrentConversation(undefined);
    dispatch(hideChatQuoteBasket());
    dispatch(hideChatPopup());
  };

  const handleCloseModalChatDetail = () => setShowChatPopupDetail(false);

  const handleFocusInputField = () => inputFIeldRef?.current?.focus();

  const userForCaseWelcomeMessage =
    chatSubscription?.chat?.[0]?.chat_users?.find(
      us => us.user.email !== login.loginName
    )?.user;

  useEffect(() => {
    if (
      userOnlineSubscription !== undefined &&
      !userOnlineSubscription?.user_online
        ?.map(userOnline => userOnline?.email)
        ?.includes(login?.loginName) &&
      showChatQuoteBasket &&
      configQuoteRequest === "CHAT"
    ) {
      sendMessage({
        variables: {
          chatId: currentChat?.id,
          senderId: supplierInfo?.id,
          content:
            "Unfortunately the Supplier is not able to connect at this moment.   You will be notified of his response via email or via the notifcations alert at the top of the page.  Feel free to add more messages here as that supplier will receive them all.",
          quote_request_id: currentQuoteBasket?.id + ""
        }
      });
    }
  }, []);

  useEffect(() => {
    if (reRangeChatSubscription || chatSubscription) {
      const tempReRangeChatSubscription = chatSubscription?.chat
        ?.map(item => ({
          ...item,
          timestamp: item?.messages?.[item?.messages?.length - 1]?.created_at
        }))
        ?.sort((x, y) => moment(y?.timestamp).diff(moment(x?.timestamp)));

      const currentChat1 = chatSubscription?.chat?.find(el => {
        return chatStore?.chatWithVendor
          ? el.chat_users
              .filter(user => user.user.vendor !== null)
              .find(user => user.user.vendor.supplier_vendorId)?.user?.vendor
              ?.supplier_vendorId === chatStore?.chatWithVendor
          : el.id === currentChatId;
      });

      if (
        tempReRangeChatSubscription &&
        tempReRangeChatSubscription.length > 0
      ) {
        setCurrentChatId(tempReRangeChatSubscription[0]?.id);
        setCurrentConversation(currentChat1);
      }

      setReRangeChatSubscription({
        ...reRangeChatSubscription,
        chat: tempReRangeChatSubscription
      });
    }
  }, [chatSubscription]);

  useEffect(() => {
    if (reRangeChatSubscription) {
      if (usingMarketplaceSupport)
        setSupportAgentChat(
          reRangeChatSubscription?.chat?.filter(chat => {
            if (chat?.chat_users?.find(user => user?.user?.isSupportAgent))
              return true;
          })
        );
      else setCurrentConversation({});
    }
  }, []);

  return (
    <>
      <Box
        className={classes.chatPopupWrapper}
        style={{
          position: usingChatPopup ? "absolute" : "relative",
          width: usingChatPopup ? "90vw" : "100%",
          borderRadius: usingBorderRadius ? "20px" : 0,
          ...style
        }}
      >
        {loading || chatSubscription === undefined ? (
          <Box margin="auto">
            <CircularProgress />
          </Box>
        ) : chatSubscription?.chat?.length === 0 ? (
          <Box style={{ marginLeft: 15, marginTop: 15 }}>
            <Typography variant="h4" style={{ fontWeight: 700 }}>
              You don't have any messages from any supplier
            </Typography>
          </Box>
        ) : (
          <>
            {usingChatPopup && (
              <Box className={classes.chatHeader}>
                <IconButton
                  style={{ padding: 0, paddingBottom: 12 }}
                  onClick={handleCloseModal}
                >
                  <CancelIcon />
                </IconButton>
              </Box>
            )}
            <Grid container className={classes.chatPopupContainer}>
              <Grid
                key="1"
                item
                xs={12}
                lg={3}
                xl={3}
                md={3}
                style={{
                  // height: "100%",
                  //padding: 30,
                  background: "#fff 0% 0% no-repeat padding-box",
                  borderTopLeftRadius: "20px",
                  borderBottomLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px"
                  //borderRadius: "20px 0px 0px 20px"
                }}
              >
                <List className={classes.chatContainerLeft}>
                  {configSupportChat === "ON" && usingMarketplaceSupport && (
                    <>
                      <Typography className={classes.listSubHeader}>
                        Marketplace Support
                      </Typography>
                      {supportAgentChat?.map(el => (
                        <ListItem
                          className={classes.listItem}
                          style={
                            currentConversation?.id === el?.id
                              ? {
                                  background: "rgba(0, 0, 0, 0.04)"
                                }
                              : {}
                          }
                          button
                          key="0"
                          onClick={() => {
                            setCurrentChatId(el.id);
                            setCurrentConversation(el);
                            dispatch(hideChatQuoteBasket());
                            handleFocusInputField();
                            if (maxWidth958) setShowChatPopupDetail(true);
                          }}
                        >
                          <ListItemAvatar>
                            <StyledBadge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right"
                              }}
                              variant="dot"
                            >
                              <Avatar
                                className={classes.avatar}
                                style={{
                                  color: "#AAAAAA"
                                }}
                              >
                                M
                              </Avatar>
                            </StyledBadge>
                          </ListItemAvatar>
                          <ListItemText
                            primary={`${
                              el.chat_users?.find(
                                item => item.user.isSupportAgent
                              )?.user?.firstName
                            } ${
                              el.chat_users?.find(
                                item => item.user.isSupportAgent
                              )?.user?.lastName
                            }`}
                            secondary={trimString(
                              "Can we help you find the product you are looking for?",
                              15
                            )}
                          />
                        </ListItem>
                      ))}

                      <Divider className={classes.divider} />
                    </>
                  )}

                  {configSupplierChat === "ON" &&
                    reRangeChatSubscription?.chat?.length > 0 && (
                      <>
                        <Typography className={classes.listSubHeader}>
                          Suppliers
                        </Typography>
                        {reRangeChatSubscription?.chat
                          ?.filter(chat => {
                            if (
                              !chat.chat_users.find(
                                user => user?.user?.isSupportAgent
                              )
                            )
                              return true;
                          })
                          ?.map((el, i) => {
                            const user = el.chat_users.find(
                              us => us.user.email !== login.loginName
                            )?.user;

                            return (
                              <React.Fragment key={el.id}>
                                <ListItem
                                  button
                                  className={classes.listItem}
                                  style={
                                    currentConversation?.id === el?.id
                                      ? {
                                          background: "rgba(0, 0, 0, 0.04)"
                                        }
                                      : {}
                                  }
                                  onClick={() => {
                                    setCurrentChatId(el.id);
                                    setCurrentConversation(el);
                                    // dispatch(hideChatQuoteBasket());
                                    handleFocusInputField();
                                    setStatusMarketplaceSupport(false);

                                    if (maxWidth958)
                                      setShowChatPopupDetail(true);
                                  }}
                                >
                                  <ListItemAvatar>
                                    <StyledBadge
                                      overlap="circular"
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right"
                                      }}
                                      variant="dot"
                                    >
                                      <Avatar
                                        className={classes.avatar}
                                        style={{
                                          color:
                                            currentConversation?.id === el?.id
                                              ? "#3F57C6"
                                              : "#AAAAAA"
                                        }}
                                      >
                                        {user?.vendor?.brand
                                          .split(" ", 1)
                                          .map(item => item.slice(0, 1))
                                          .join("")}
                                      </Avatar>
                                    </StyledBadge>
                                  </ListItemAvatar>
                                  <ListItemText
                                    className={
                                      currentConversation?.id === el?.id
                                        ? classes.listItemText
                                        : ""
                                    }
                                    primary={
                                      user?.vendor?.brand || user?.firstName
                                    }
                                    secondary={
                                      el?.messages?.length !== 0
                                        ? trimString(
                                            el?.messages?.[
                                              el?.messages?.length - 1
                                            ]?.content,
                                            15
                                          )
                                        : "No Messages"
                                    }
                                  />
                                </ListItem>
                                <Divider className={classes.divider} />
                              </React.Fragment>
                            );
                          })}
                      </>
                    )}
                </List>
              </Grid>
              <Grid
                item
                xs={9}
                style={{
                  paddingRight: 12,
                  display: maxWidth958 ? "none" : "flex",
                  flexDirection: "column",
                  maxHeight: maxWidth958 ? "100%" : "80vh",
                  padding: 40
                }}
              >
                {configSupplierChat === "ON" &&
                !currentConversation &&
                pathname.includes("quote") ? (
                  <Box
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    background="#eee"
                  >
                    <Typography
                      variant="h5"
                      style={{
                        textAlign: "center"
                      }}
                    >
                      Do you have any Additional Requirements for{" "}
                      {userForCaseWelcomeMessage?.vendor?.brand
                        .split(" ", 1)
                        .map(item => item.slice(0, 4))
                        .join("")}{" "}
                      ?
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{
                        textAlign: "center"
                      }}
                    >
                      Please chat to the supplier to get more detail
                    </Typography>
                  </Box>
                ) : (
                  <MessageDetail
                    usingMarketplaceSupport={usingMarketplaceSupport}
                    currentChat={currentChat}
                    currentConversation={currentConversation}
                    sendMessage={sendMessage}
                    setCurrentConversation={setCurrentConversation}
                    supplierBasket={supplierBasket}
                    inputFIeldRef={inputFIeldRef}
                    supplierInfo={supplierInfo}
                    count={count}
                    setCount={setCount}
                    chatSubscription={chatSubscription}
                    statusMarketplaceSupport={statusMarketplaceSupport}
                    messageBoxMutation={sendMessage}
                    currentQuoteBasket={currentQuoteBasket}
                    usingScrollToBottom={usingScrollToBottom}
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      {/*<Modal*/}
      {/*  open={showChatPopupDetail}*/}
      {/*  onClose={handleCloseModalChatDetail}*/}
      {/*  className={classes.chatWrapper}*/}
      {/*>*/}
      {/*  <Box*/}
      {/*    style={{*/}
      {/*      width: "95vw",*/}
      {/*      height: "85vh",*/}
      {/*      background: "#fff",*/}
      {/*      padding: 10*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <MessageDetail*/}
      {/*      usingMarketplaceSupport={usingMarketplaceSupport}*/}
      {/*      currentChat={currentChat}*/}
      {/*      currentConversation={currentConversation}*/}
      {/*      sendMessage={sendMessage}*/}
      {/*      handleCloseModal={() => {*/}
      {/*        setShowChatPopupDetail(false);*/}
      {/*        dispatch(hideChatQuoteBasket());*/}
      {/*      }}*/}
      {/*      setCurrentConversation={setCurrentConversation}*/}
      {/*      mobileView*/}
      {/*      supplierBasket={supplierBasket}*/}
      {/*      inputFIeldRef={inputFIeldRef}*/}
      {/*      supplierInfo={supplierInfo}*/}
      {/*      count={count}*/}
      {/*      setCount={setCount}*/}
      {/*      chatSubscription={chatSubscription}*/}
      {/*      statusMarketplaceSupport={statusMarketplaceSupport}*/}
      {/*      messageBoxMutation={sendMessage}*/}
      {/*      currentQuoteBasket={currentQuoteBasket}*/}
      {/*      usingScrollToBottom={usingScrollToBottom}*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*</Modal>*/}
    </>
  );
};

export default Chat;
