import { Box, Button, Badge } from "@material-ui/core";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MailIcon from "@material-ui/icons/Mail";
import { showChatPopup } from "../../../../redux/actions/chatActions";
import { useQuery } from "@apollo/client";
import { GET_CHAT } from "../Chat/queries";
import LoginProfileModal from "../../../AC-Header/LoginProfileModal";

const ChatFloatingIcon = () => {
  const [modal, setModal] = useState({ open: false, side: "right" });
  const securityTokenState = useSelector(
    state => state.loginReducer.securityToken,
    shallowEqual
  );
  const dispatch = useDispatch();
  const { data } = useQuery(GET_CHAT);

  return (
    <>
      <Box style={{ position: "fixed", bottom: 20, right: 20, zIndex: 1000 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ width: 70, height: 70, borderRadius: "100%" }}
          onClick={() => {
            if (securityTokenState) {
              dispatch(showChatPopup());
            } else {
              // setShowLoginDrawer(true);
              setModal({ open: true, side: "right" });
            }
          }}
        >
          <Badge
            badgeContent={data?.chat?.length || 0}
            color="secondary"
            max={5}
          >
            <MailIcon fontSize="large" />
          </Badge>
        </Button>
      </Box>
      <LoginProfileModal modalState={modal} />
    </>
  );
};

export default ChatFloatingIcon;
