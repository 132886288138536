// Enum: NONE | CHAT | MESSAGE
export const configQuoteRequest = "MESSAGE";

// Enum: ON | OFF
export const configSupportChat = "OFF";
// SupportChat

// Enum: ON | OFF
export const configSupplierChat = "ON";
// SupplierChat

// Enum: NONE | DISPLAY
export const configCart = "DISPLAY";


export const skipDeliOp = true;