/* Copyright 2020 Avetti.com Corporation - All Rights Reserved

This source file is subject to the Avetti Commerce Front End License (ACFEL 1.20)
that is accessible at https://www.avetticommerce.com/license */
import {
  FETCH_WISHLIST_SUCCESS,
  FETCH_WISHLIST_CART_SUCCESS,
  WISHLIST_ADD_REDUX,
  FETCH_WISHLIST,
  CLEAR_WISHLIST
} from "../types.js";

const initialState = {
  wishlist: []
};

const wishListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case WISHLIST_ADD_REDUX:
      return {
        ...state,
        wishlist: payload
      };
    case FETCH_WISHLIST_SUCCESS:
      return {
        ...state,
        wishlist: { ...state.wishlist }
      };
    case FETCH_WISHLIST_CART_SUCCESS:
        return {
          ...state,
          wishlist: { ...state.wishlist }
        };
    case FETCH_WISHLIST:
      return {
        ...state,
        wishlist: payload?.__Result?.items ? [...payload.__Result.items] : []
      };
    case CLEAR_WISHLIST:
      return {
        ...state,
        wishlist: []
      };
    default:
      return state;
  }
};

export default wishListReducer;
