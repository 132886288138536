/* Copyright 2020 Avetti.com Corporation - All Rights Reserved

This source file is subject to the Avetti Commerce Front End License (ACFEL 1.20)
that is accessible at https://www.avetticommerce.com/license */
import {
  FETCH_WISHLIST_SUCCESS,
  FETCH_WISHLIST_CART_SUCCESS,
  TOGGLE_WISHLIST_SUCCESS,
  WISHLIST_ADD_REDUX,
  WISHLIST_TOTAL_REDUX,
  REQUEST_WISHLIST_AFTER_WISHLIST_UPDATE,
  REQUEST_WISHLIST_AFTER_WISHLIST_UPDATE_CART,
  FETCH_WISHLIST,
  SET_WISHLIST_MODE,
  SET_WISHLIST_LOADING_AFTER_UPDATE,
  SET_WISHLIST_LOADING_AFTER_UPDATE_CART,
  CLEAR_WISHLIST
} from "../types.js";
import {
  WISHLIST_LINK,
  WISHLIST_ADD_LINK,
  WISHLIST_REMOVE_LINK,
  GET_WISHLIST,
  WISHLIST_PRODUCT_MOVE_TO_CART
} from "../links.js";
import { call, put } from "redux-saga/effects";
import { store } from "../../layout";

export const fetchWishlistAction = products => ({
  type: FETCH_WISHLIST_SUCCESS,
  payload: products
});

export const fetchWishListCartAction = products => ({
  type: FETCH_WISHLIST_CART_SUCCESS,
  payload: products
});

export const clearWishlist = () => ({
  type: CLEAR_WISHLIST
});

export const setWishListLoadingAfterUpdate = payload => ({
  type: SET_WISHLIST_LOADING_AFTER_UPDATE,
  payload
});

export const setWishListLoadingAfterUpdateCart = payload => ({
  type: SET_WISHLIST_LOADING_AFTER_UPDATE_CART,
  payload
});

export const setWishlistMode = wishlist => ({
  type: SET_WISHLIST_MODE,
  payload: wishlist
});

export const fetchWishList = () => async dispatch => {
  const api_call = await fetch(GET_WISHLIST());

  const wishlist = await api_call.json();

  const wishlistCount = wishlist.__Result.total;

  console.log("wishlist4", wishlist);
  console.log("wishlist5", wishlistCount);

  // const data = await fetch(
  //   "https://previewdev.open4business.io/preview/wishlistservice.ajx?vid=20180521148&action=get&p=1"
  // );
  // let wishlist = await data.json();

  dispatch({ type: FETCH_WISHLIST, payload: wishlist });
};

export const addFunctionWishList = wishlist => ({
  type: WISHLIST_ADD_REDUX,
  payload: wishlist
});

export const totalWishlistCount = wishlistCount => ({
  type: WISHLIST_TOTAL_REDUX,
  payload: wishlistCount
});

export const toggleWishListAction = (
  id,
  code,
  title,
  desc,
  currency_sign,
  image,
  price,
  url,
  wishListState
) => {
  return dispatch => {
    id = String(id);
    let wishListTemp = wishListState || [];

    if (wishListTemp.length < 1 || !wishListTemp.some(w => w.id == id)) {
      dispatch(
        addFunctionWishList([
          ...wishListTemp,
          { id, code, title, desc, currency_sign, image, price, url }
        ])
      );
    } else {
      let tempWishList = wishListTemp.filter(w => w.id != id);
      dispatch(addFunctionWishList([...tempWishList]));
    }
  };
};

const api = async params => {
  try {
    let link = params.link;
    const res = await fetch(
      link ? link() : WISHLIST_LINK(params.language, params.vid)
    );

    const json = await res.json();
    let wishlists = json.__Result;

    return wishlists;
  } catch (error) {
    console.warn("fetch WISHLIST_LINK failed", error);
  }
};

export function* fetchWishListAction(action) {
  let language = store.getState().mainReducer.lang;
  let currency = store.getState().mainReducer.currency;
  let params = { language, currency };

  try {
    const result = yield call(api, params);
    const suppliersResult = yield call(api, { ...params, link: WISHLIST_LINK });
    console.log({ suppliersResult });
    yield put(fetchWishlistAction(result));
    //yield put(fetchSupplierBasketSuccessAction(suppliersResult));
    if (action.type === REQUEST_WISHLIST_AFTER_WISHLIST_UPDATE) {
      yield put(setWishListLoadingAfterUpdate(false));
    }
    
  } catch (error) {
    console.error("fetch wishlist saga error", error);
    if (action.type === REQUEST_WISHLIST_AFTER_WISHLIST_UPDATE) {
      yield put(setWishListLoadingAfterUpdate(false));
    }
  }
}

// export function* fetchWishListCartAction(action) {
//   let language = store.getState().mainReducer.lang;
//   let currency = store.getState().mainReducer.currency;
//   let params = { language, currency };

//   try {
//     const result = yield call(api, params);
//     const suppliersResult = yield call(api, { ...params, link: WISHLIST_LINK });
//     console.log({ suppliersResult });
//     yield put(fetchWishListCartAction(result));
//     //yield put(fetchSupplierBasketSuccessAction(suppliersResult));
//     if (action.type === REQUEST_WISHLIST_AFTER_WISHLIST_UPDATE_CART) {
//       yield put(setWishListLoadingAfterUpdateCart(false));
//     }
    
//   } catch (error) {
//     console.error("fetch wishlist saga error", error);
//     if (action.type === REQUEST_WISHLIST_AFTER_WISHLIST_UPDATE_CART) {
//       yield put(setWishListLoadingAfterUpdateCart(false));
//     }
//   }
// }

export const addAuctionItemToWishList = (
  id,
  code,
  title,
  desc,
  currency_sign,
  image,
  price,
  url,
  wishListState
) => {
  return dispatch => {
    id = String(id);
    let wishListTemp = wishListState || [];
    wishListTemp = wishListTemp.filter(w => w.id != id); // remove already added product when they were added by bid button event
    dispatch(
      addFunctionWishList([
        ...wishListTemp,
        { id, code, title, desc, currency_sign, image, price, url }
      ])
    );
  };
};
