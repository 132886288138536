import { WISHLIST_UPDATE } from "../../../redux/links";
import { REQUEST_WISHLIST_AFTER_WISHLIST_UPDATE } from "../../../redux/types";
import {
  fetchWishList,
  setWishListLoadingAfterUpdate
} from "../../../redux/actions/wishListActions";

const handleDeleteProductFromWishlist = (itemid, dispatch) => {
  let form = new FormData();

  //form.append("itemid", `${itemId}`);
  form.append("vid", "20210820389");
  form.append("action", "delete");
  //form.append("iids", `${itemId}`);

  dispatch(setWishListLoadingAfterUpdate(true));

  fetch(WISHLIST_UPDATE(itemid), {
    method: "POST",
    body: form,
    headers: {
      Accept: "*/*",
      credentials: "same-origin"
    },
    mimeType: "multipart/form-data",
    data: form
  })
    .then(res => {
      dispatch({ type: REQUEST_WISHLIST_AFTER_WISHLIST_UPDATE });
      dispatch(fetchWishList());
    })
    .catch(err => {
      console.error("basket update failed", err.message);
      dispatch(setWishListLoadingAfterUpdate(false));
    });
};

export default handleDeleteProductFromWishlist;
