// extracted by mini-css-extract-plugin
export var container = "WishListMobile-module--container--YUq0W";
export var arabic = "WishListMobile-module--arabic--htPLW";
export var active = "WishListMobile-module--active--Ia-RP";
export var outerContainer = "WishListMobile-module--outerContainer--Bq8tr";
export var wishListTitle = "WishListMobile-module--wishListTitle--5ajHa";
export var wrapper = "WishListMobile-module--wrapper--QMlXf";
export var wishListWrapper = "WishListMobile-module--wishListWrapper--cNLI6";
export var wishItemWrapper = "WishListMobile-module--wishItemWrapper--XupFh";
export var wishItemImage = "WishListMobile-module--wishItemImage--xTDko";
export var wishItemDetails = "WishListMobile-module--wishItemDetails--ghGvL";
export var wishItemDesc = "WishListMobile-module--wishItemDesc--YfZ+x";
export var wishItemPrice = "WishListMobile-module--wishItemPrice--3FVvN";
export var wishItemActionWrapper = "WishListMobile-module--wishItemActionWrapper--AmU7V";
export var wishItemCode = "WishListMobile-module--wishItemCode--kidvw";
export var wishItemRemove = "WishListMobile-module--wishItemRemove--m4Q5o";
export var noItemsFound = "WishListMobile-module--noItemsFound--NTQdi";
export var noItemsFoundInWish = "WishListMobile-module--noItemsFoundInWish--zz10p";