/* Copyright 2020 Avetti.com Corporation - All Rights Reserved

This source file is subject to the Avetti Commerce Front End License (ACFEL 1.20)
that is accessible at https://www.avetticommerce.com/license */
import React, { useState, useEffect, useReducer } from "react";
import { useLocation } from "@reach/router";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import MailchimpSub from "../components/AC-Modals/MailchimpSub";
import MenuTopBar from "../components/AC-Header/MenuTopBar";
import Compare from "../components/AC-Header/Compare";
import Common from "../shared/components/Common";
import Footer from "../components/AC-Footer/Footer";
import LocationBar from "../components/AC-Location/LocationBar";
import PromotionComponent from "../components/AC-Advertising/PromotionComponent";
import CookieDialog from "../components/AC-Cookie/CookieDialog";
import handleError from "../functions/handleError";
import ChatFloatingIcon from "../components/AC-ProductPage/Components/ChatFloatingIcon/ChatFloatingIcon";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  HttpLink,
  InMemoryCache,
  split
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import {
  configQuoteRequest,
  configSupportChat,
  configSupplierChat
} from "../../store-config";
import { shallowEqual, useSelector } from "react-redux";
import ChatModal from "@/components/AC-ProductPage/Components/Chat/ChatModal";

let wsLink = null;
const SUBSCRIPTIONS_API_ENDPOINT = `wss://${process.env.GATSBY_GRAPHQL_ENDPOINT}`;
const HTTPS_API_ENDPOINT = `https://${process.env.GATSBY_GRAPHQL_ENDPOINT}`;

const HOCLayout = ({ children, params }) => {
  const location = useLocation();
  const [cookie, setCookie] = useState(false);
  const tokenFromRedux = useSelector(
    state => state.loginReducer.token,
    shallowEqual
  );
  const [token, setToken] = useState();

  useEffect(() => {
    setToken(tokenFromRedux);
  }, [tokenFromRedux]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      let cookieRule = JSON.parse(localStorage.getItem("cookieAcceptance"));
      if (cookieRule && cookieRule.isAccept) {
        setCookie(false);
      } else {
        setCookie(true);
      }
    }
  }, []);

  const cookieAccept = () => {
    if (typeof window !== "undefined") {
      let consent = {
        date: new Date(),
        consent: "Manage-Cookie: ON",
        isAccept: true
      };
      localStorage.setItem("cookieAcceptance", JSON.stringify(consent));
      setCookie(false);
    }
  };

  const data = useStaticQuery(graphql`
    query menuQueryAndMenuQuery {
      allAvettiMenu {
        nodes {
          childs {
            cid
            name
            URL
            description
            image
            thumbnail
            vid
            childs {
              cid
              name
              URL
              description
              image
              thumbnail
              vid
            }
          }
        }
      }
      allAvettiCategory(filter: { url: { in: "stores" } }) {
        nodes {
          name

          items {
            id
            title
            code
            desc
            properties {
              lng
              lat
            }
          }
        }
      }
    }
  `);

  if (typeof window !== "undefined") {
    window.addEventListener("error", function (event) {
      if (event.error.hasBeenCaught !== undefined) {
        return false;
      }
      event.error.hasBeenCaught = true;
      handleError(event.error);
    });
  }

  wsLink =
    !wsLink &&
    typeof window !== "undefined" &&
    token &&
    new WebSocketLink({
      uri: SUBSCRIPTIONS_API_ENDPOINT,
      options: {
        reconnect: true,
        reconnectionAttempts: 50,
        lazy: true,
        timeout: 20000,
        connectionParams: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      }
    });

  //  wsLink.subscriptionClient.close();
  const authLink = new ApolloLink((operation, forward) => {
    // add the authorization to the headers

    operation.setContext(({ headers = {}, ...context }) => ({
      headers: {
        ...headers,
        ...(token ? { Authorization: `Bearer ${token}` } : {})
      },
      ...context
    }));

    return forward(operation);
  });

  const link =
    typeof window !== "undefined" &&
    createHttpLink({
      uri: HTTPS_API_ENDPOINT,
      credentials: "include",
      fetch
    });

  const splitLink =
    typeof window !== "undefined" && wsLink
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === "OperationDefinition" &&
              definition.operation === "subscription"
            );
          },
          wsLink,
          authLink.concat(link)
        )
      : typeof window !== "undefined"
      ? authLink.concat(link)
      : link;

  const client = new ApolloClient({
    link:
      typeof window !== "undefined"
        ? splitLink
        : new HttpLink({ uri: HTTPS_API_ENDPOINT }),
    cache: new InMemoryCache()
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const userData = JSON.parse(sessionStorage.getItem("UserData"));
      const dataTracking = JSON.parse(localStorage.getItem("dataTracking"));

      const pageHref = window.location.href;

      const objectTracking = {
        timestamp: new Date(),
        // ip: response.ip,
        navigatorAgent: navigator.userAgent
      };

      if (userData) {
        objectTracking.loginName = userData.loginname;
        objectTracking.ipAddress = userData.ipAddress;
      }

      if (!dataTracking) {
        objectTracking.visitedPage = [pageHref];
        localStorage.setItem("dataTracking", JSON.stringify(objectTracking));
      } else {
        objectTracking.visitedPage = [...dataTracking.visitedPage, pageHref];
        localStorage.setItem("dataTracking", JSON.stringify(objectTracking));
      }
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <Common />
      <div>
        <MenuTopBar
          data={data.allAvettiMenu.nodes[0].childs}
          brands={data.allAvettiCategory.nodes[0].itemsFirstPage}
        />
        {/* <LocationBar /> */}
        <PromotionComponent />
        <Compare />
      </div>
      {children}
      <MailchimpSub />
      {cookie && !location.pathname.includes("cookies") && (
        <CookieDialog acceptCookieCloseDialog={cookieAccept} />
      )}
      <ChatModal params={params} />
      {configSupportChat === "ON" &&
        ((configSupplierChat === "OFF" && location.pathname !== "/stores/") ||
        (configSupportChat === "ON" &&
          configQuoteRequest === "NONE" &&
          !location.pathname.includes("/merchandise/")) ? (
          <ChatFloatingIcon />
        ) : (
          configSupportChat === "ON" || configQuoteRequest === "CHAT"
        )) && <ChatFloatingIcon />}
      {/*<ChatForQuoteBasket />*/}
      <Footer />
    </ApolloProvider>
  );
};

HOCLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default HOCLayout;
