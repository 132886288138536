import React, { useState, useEffect, useContext } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import WishListModal from "./WishListModal";
import WishListMobile from "./WishListMobile";
import { I18nContext } from "../../../i18n";
import capitalize from "../../../functions/capitalize";
import { addFunctionWishList } from "../../../redux/actions/wishListActions.js";
import { setHTMLElementFixedPosition } from "../../../functions/Utilities.js";
import { toggleWishListAction } from "../../../redux/actions/wishListActions.js";
import WishListIcon from "../../../assets/img/whislist.png";
import ReactTooltip from 'react-tooltip';


export default function WishListCustomModal() {
  const dispatch = useDispatch();
  const { translate, langCode } = useContext(I18nContext);

  const [showModal, setShowModal] = useState(false);

  const [pathname, SetPathname] = useState(null);

  const isBrowser = typeof window !== "undefined";

  useEffect(() => {
    if (isBrowser) {
      SetPathname(window.location.pathname)
    }
  }, []);

  const wishListState = useSelector(
    state => state.wishListReducer.wishlist,
    shallowEqual
  );

  const wishListCountState = useSelector(
    state => state.wishListReducer.wishlistCount,
    shallowEqual
  );

  const handleLogin = () => {
    document.getElementById("login-icon-btn").click();
  };

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );



  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  useEffect(() => {
    // This will set aria-hidden of html element based on the value of showModal
    setHTMLElementFixedPosition(showModal);
  }, [showModal]);

  //const LOCAL_STORAGE_WISHLIST_NAME = "wishList";

  const handleOpen = () => setShowModal(true);

  const handleClose = () => setShowModal(false);

  //useEffect(() => {
  // if (typeof localStorage !== undefined) {
  //   let storedWishListString = localStorage.getItem(
  //     LOCAL_STORAGE_WISHLIST_NAME
  //   );
  //   let storedWishListObject = JSON.parse(storedWishListString);
  //   if (storedWishListObject)
  //     dispatch(addFunctionWishList([...storedWishListObject]));
  // }
  //}, []);

  //useEffect(() => {
  // if (typeof localStorage !== undefined) {
  //   localStorage.setItem(
  //     LOCAL_STORAGE_WISHLIST_NAME,
  //     JSON.stringify(wishListState)
  //   );
  // }
  //}, [wishListState]);

  const toggleWish = (
    e,
    id,
    code,
    title,
    desc,
    currency_sign,
    image,
    price,
    url
  ) => {
    e.preventDefault();
    dispatch(
      toggleWishListAction(
        id,
        code,
        title,
        desc,
        currency_sign,
        image,
        price,
        url,
        wishListState
      )
    );
  };

  const renderWishListWindow = () => {
    /*     if (isMobileState) { */
    return (
      <WishListMobile
        show={showModal}
        close={handleClose}
        toggleWish={toggleWish}
      />
    );
    /*   } else {
      return <WishListModal show={showModal} close={handleClose} />;
    } */
  };

  return (
    <React.Fragment>
      {renderWishListWindow()}
      {loginNameState == "" ? (
        <div
          id="wishlist-icon-btn"
          className="icon-container"
          onClick={handleLogin}
        >
          <div className="icon-wrapper">
            {/* <i className="material-icons">
            {wishListState.length > 0 ? `favorite` : `favorite_border`}
          </i> */}


            <img src={pathname && pathname == "/" ? "https://ik.imagekit.io/ofb/market/category/images/wishlist_Vkmqxlj8_.png" : "https://ik.imagekit.io/ofb/ofb/heart_xW2M8AamnWC.svg"} alt="Icon" />
            {
              !isMobileState && (
                <span
                  className="miniwishlist-new-count"
                  style={
                    wishListState.length > 0
                      ? null
                      : { display: "none" }
                  }
                >
                  {wishListState.length}
                </span>
              )
            }

          </div>
        </div>) : (

        <div
          id="wishlist-icon-btn"
          className="icon-container"
          onClick={handleOpen}
        >
          <div className="icon-wrapper">
            {/* <i className="material-icons">
    {wishListState.length > 0 ? `favorite` : `favorite_border`}
  </i> */}
            {
              isMobileState ? <img src="https://ik.imagekit.io/ofb/dbz/Whishlist_jD34c4fUjCu.svg?updatedAt=1639484484334" alt="Icon" /> : <img src="https://ik.imagekit.io/ofb/dbz/Whishlist_jD34c4fUjCu.svg?updatedAt=1639484484334" data-background-color="#3F57C6" data-tip="Wishlist" data-class="datatool" data-offset="{'top': 10, 'left': 10}" alt="Icon" />
            }
            
            {
              !isMobileState && (
                <span
                  className="miniwishlist-new-count"
                  style={
                    wishListState.length > 0
                      ? null
                      : { display: "none" }
                  }
                >
                  {wishListState.length}
                </span>
              )
            }
          </div>
        </div>

      )}
    </React.Fragment>
  );
}
